@import '../../../styles/common/media_queries';

.filter_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: auto;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
  border-radius: 12px;
  z-index: 3;
  min-width: 360px;
  padding: 15px 15px 30px 15px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  .filter_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    margin-top: auto;
    .m_lr_6 {
      margin: 0 6px;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 2px solid #2c2c2c;
      color: #2c2c2c;
      border-radius: 24px;
      height: 48px;
      min-width: 120px;
      max-width: 150px;
      font-family: 'gtWalsheim';
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      &.dark {
        background: #2c2c2c;
        color: #fff;
      }
      &.dark_disabled {
        border: 2px solid #d9d9d9;
        background: #d9d9d9;
        color: #5e5e5e;
      }
    }
  }
  .pills_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .pill {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 12px;
      width: max-content;
      background-color: #fff;
      border: 1px solid #2c2c2c;
      border-radius: 24px;
      margin: 4px;
      cursor: pointer;
      width: 47% !important;
      justify-content: center;
      &:first-child {
        margin-left: 0;
      }
      .txt {
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 500;
        color: #2c2c2c;
      }
      .bold {
        font-family: 'gtWalsheim';
        font-weight: 700;
      }
      .rounded_close {
        width: 16px;
        height: 16px;
        background-image: url(../../../styles/images/rounded-close.svg);
        background-repeat: no-repeat;
        margin-left: 5px;
      }
      &.draft {
        border: 1px solid #a65523;
        .txt {
          color: #a65523;
        }
      }
      &.goLive {
        border: 1px solid #107f47;
        .txt {
          color: #107f47;
        }
      }
      &.goOffline {
        border: 1px solid #707070;
        .txt {
          color: #707070;
        }
      }
      &.archive {
        border: 1px solid #d91734;
        .txt {
          color: #d91734;
        }
      }
      &.selected {
        &.draft {
          background-color: #a65523;
        }
        &.goLive {
          background-color: #107f47;
        }
        &.goOffline {
          background-color: #707070;
        }
        &.archive {
          background-color: #d91734;
        }
      }
    }
  }
  .collapse_main_wrapper {
    // max-height: 200px;
    overflow: auto;
    padding-right: 10px;
  }
  .collapsible_wrapper {
    .collapse_button {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e4e4e4;
      padding: 10px 0;
      cursor: pointer;
      .txt {
        font-family: 'gtWalsheim';
        font-size: 16px;
        color: #2c2c2c;
        font-weight: 500;
      }
      .collapse_icon {
        width: 14px;
        height: 2px;
        background-image: url(../../../styles/images/minus.svg);
        background-repeat: no-repeat;
        margin-left: auto;
      }
      .non_collapse {
        width: 13px;
        height: 13px;
        background-image: url(../../../styles/images/plus-thin.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
      }
    }
    .filter_list {
      .filter_data {
        padding: 25px 0px;
        border-bottom: 1px solid #e4e4e4;
        .list {
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 45px;
          }
          @include media_query(M) {
            &:last-child {
              margin-bottom: 0;
            }
          }
          label {
            font-family: 'gtWalsheim';
            font-size: 16px;
          }
          &.selected {
            label {
              font-family: 'gtWalsheim';
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
