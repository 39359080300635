.outerDiv {
  display: flex;
  align-items: center;
}

.selectedCheckbox {
  background-image: url(../../../styles/images/blue_checked_box.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  // margin-right: 10px;
  flex-shrink: 0;
}

.unselectedCheckbox {
  background-image: url(../../../styles/images/unselectedCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.disabledTrueCheckbox {
  background-image: url(../../../styles/images/blue_checked_box.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}

.disabledCheckbox {
  background-image: url(../../../styles/images/disabledCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}
