@import '../../../styles/common/media_queries';

.card_holder {
  width: 100%;
  margin-bottom: 12px;
  font-family: 'gtWalsheim';
  .card_with_courses {
    background-color: #fff;
    box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
    border-radius: 12px;
    display: flex;
    min-height: 108px;
    @include media_query(XS) {
      min-width: initial;
    }
    @include media_query(M) {
      min-width: fit-content;
    }
    &:hover {
      .delete_section {
        // display: block;
        display: flex;
        &:hover {
          cursor: pointer;
          background-color: #d91734;
          .delete_icon {
            background-image: url(../../../styles/images/delete_whitex_icon.svg);
          }
        }
      }
    }
    &.disabled {
      //pointer-events: none;
      .delete_section {
        cursor: pointer;
        background-color: #f0f0f0;
        .delete_icon {
          background-image: url(../../../styles/images/disable_cross_icon.svg);
        }
      }
      .course_type_section {
        .title {
          .title_txt {
            opacity: 0.67;
          }
        }
        .radio_cotaniner {
          .inner_wrapper {
            opacity: 0.67;
          }
        }
      }
    }
    // Card without course access
    &.card_without_courses {
      min-height: 48px;
      height: 48px;
      .delete_section {
        &:hover {
          cursor: pointer;
          background-color: #38539a;
          .delete_icon {
            background-image: url(../../../styles/images/leader_white_icon.svg) !important;
            // background-image: url(../../../styles/images/disabled_leader_icon.svg) !important;
          }
        }
        &.live {
          &:hover {
            .delete_icon {
              background-image: url(../../../styles/images/disabled_leader_icon.svg) !important;
            }
          }
        }
      }
      &.disabled {
        // pointer-events: none;
        .delete_section {
          cursor: pointer;
          background-color: #f0f0f0;
          .delete_icon {
            background-image: url(../../../styles/images/disabled_leader_icon.svg);
          }
        }
        .course_type_section {
          .title {
            .title_txt {
              opacity: 0.67;
            }
          }
          .radio_cotaniner {
            .inner_wrapper {
              opacity: 0.67;
            }
          }
        }
      }

      .delete_section {
        cursor: pointer;
        .delete_icon {
          width: 16px;
          height: 16px;
          background-image: url(../../../styles/images/leader_icon.svg);
        }
      }
      .course_type_section {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #2c2c2c;
          margin: 0;
          @include media_query(M) {
            font-size: 18px;
            line-height: 21px;
          }
        }
        .radio_cotaniner {
          display: none;
        }
      }
    }
    .delete_section {
      min-width: 40px;
      background-color: #f1f2ff;
      border-radius: 12px 0px 0px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .delete_icon {
        background-image: url(../../../styles/images/delete_blue_icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
      &.disabled {
        cursor: pointer;
        background-color: #f0f0f0;
        .delete_icon {
          background-image: url(../../../styles/images/disable_cross_icon.svg);
        }
        &:hover {
          cursor: pointer;
          // background-color: #d91734;
          background-color: #f0f0f0;
          .delete_icon {
            // background-image: url(../../../styles/images/delete_whitex_icon.svg);
            background-image: url(../../../styles/images/disable_cross_icon.svg);
          }
        }
      }
    }
    .course_type_section {
      padding: 12px 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.single_line_course_type {
        display: flex;
        .radio_cotaniner {
          @include media_query(XS) {
            flex-direction: column-reverse;
            gap: 10px;
          }
          @include media_query(M) {
            flex-direction: row;
            gap: initial;
          }
          .inner_wrapper {
            display: flex;
            padding-right: 0;
            .radio_wrapper {
              margin-bottom: 0 !important;
              padding-right: 16px;
            }
          }
        }
        .tooltip_wrapper {
          @include media_query(XS) {
            top: 35px !important;
            bottom: auto;
            left: 0 !important;
            right: 0;
            margin: auto;
            transform: translate(-47%, 0);
          }
          @include media_query(M) {
            top: 30px !important;
            left: -18px !important;
            transform: translate(0, 0);
          }
          .arrow_up {
            @include media_query(XS) {
              margin: auto;
            }
            @include media_query(M) {
              margin: initial;
            }
          }
        }
      }
      .title {
        display: flex;
        align-items: center;
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #2c2c2c;
        margin-bottom: 10px;
        @include media_query(M) {
          font-size: 18px;
        }
      }
      .info_icon {
        position: relative;
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/i-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 8px;
        cursor: pointer;
        &.selected {
          background-image: url(../../../styles/images/i-icon-selected.svg);
        }
        .tooltip_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: absolute;
          top: 30px;
          left: -18px;
          width: 220px;
          // min-height: 84px;
          min-height: 58px;
          padding: 8px 16px 16px 16px;
          background: #ffffff;
          border-radius: 12px;
          /* Card Drop Shadow */
          box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
          z-index: 1;
          .tooltip_details {
            display: block;
          }
          .arrow_up {
            position: relative;
            top: -20px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white;
          }
          .tooltip_heading {
            font-size: 16px;
            font-weight: 700;
            font-family: 'gtWalsheim';
            color: #2c2c2c;
            line-height: 1;
            margin-bottom: 8px;
          }
          .tooptip_list {
            .tooltip_item {
              font-size: 16px;
              font-weight: 500;
              font-family: 'gtWalsheim';
              color: #2c2c2c;
            }
          }
        }
      }
      .radio_cotaniner {
        display: flex;
        .inner_wrapper {
          display: block;
          min-width: 112px;
          padding-right: 16px;
          &:first-child {
            @include media_query(M) {
            }
          }
          .radio_wrapper {
            display: flex;
            align-items: center;
            justify-content: start;
            &:nth-child(1) {
              margin-bottom: 12px;
            }
            &:nth-child(2) {
              margin-right: 0px;
            }
            .radio_btn {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .course_type {
              font-family: 'gtWalsheim';
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #2c2c2c;
              white-space: nowrap;
              @include media_query(M) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
