/** gtWalsheim **/

/* gtWalsheim-Regular */
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./gtWalsheim/gt-walsheim-regular.eot');
  src: url('./gtWalsheim/gt-walsheim-regular.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-regular.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* gtWalsheim-Medium */
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./gtWalsheim/gt-walsheim-medium.eot');
  src: url('./gtWalsheim/gt-walsheim-medium.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-Medium.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/** gtWalsheim Bold **/
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./gtWalsheim/gt-walsheim-bold.eot');
  src: url('./gtWalsheim/gt-walsheim-bold.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-bold.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-bold.svg') format('svg'),
  // url('./gtWalsheim/gt-walsheim-bold.eot') format('embedded-opentype'),
  // url('./gtWalsheim/gt-walsheim-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
