@import '../../../../styles/common/media_queries';

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 1000;
  .adapt_file_modal {
    position: relative;
    margin: auto;
    background-color: #fff;
    padding: 20px 30px;
    @include media_query(XS) {
      width: 335px;
      height: 260px;
    }
    @include media_query(M) {
      width: 400px;
      height: 300px;
    }
    @include media_query(L) {
    }

    .title_wrapper {
      margin-bottom: 30px;
      .title {
        font-family: 'gtWalsheim';
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }
    .middle_body_wrapper {
      padding-bottom: 15px;
      overflow-y: auto;
      .status_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f2ff;
        border-radius: 24px;
        height: 48px;
        margin-bottom: 12px;
        &.success_tab {
          background-color: #f0fae5;
          .txt {
            color: #107f47;
            margin-right: 0;
          }
          .inprogress_icon {
            display: none;
          }
        }
        &.failure_tab {
          background-color: #ffebeb;
          .txt {
            color: #d91734;
            margin-right: 0;
          }
          .inprogress_icon {
            display: none;
          }
        }
        @include media_query(M) {
          height: 36px;
        }
        .txt {
          font-family: 'gtWalsheim';
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: #38539a;
          margin-right: 10px;
        }
        .inprogress_icon {
          width: 16px;
          height: 16px;
          background-image: url(../../../../styles/images/inprogress_icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          animation: rotation 2s infinite linear;
        }
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }
      .description {
        font-family: 'gtWalsheim';
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #2c2c2c;
        @include media_query(M) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .btn_wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      background-color: #ffffff;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 205px;
        height: 36px;
        background-color: #d9d9d9;
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #5e5e5e;
        border-radius: 24px;
        cursor: pointer;
        &.active {
          background-color: #2c2c2c;
          color: #ffffff;
        }
      }
    }
  }
  @include media_query(XS) {
    display: flex;
    align-items: center;
  }
}
