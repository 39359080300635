@import '../../../styles/common/media_queries';

.header_main_wrapper {
  @include media_query(XS) {
    position: relative;
    width: 100%;
    z-index: 2;
  }
  @include media_query(M) {
    position: fixed;
    width: calc(100% - 65px);
    top: 0;
    right: 0;
    z-index: 3;
    margin-bottom: 40px;
  }
  &.without_sidenav {
    @include media_query(M) {
      width: 100%;
    }
  }
  .inner_wrapper {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    @include media_query(XS) {
    }
    @include media_query(M) {
    }
  }
  .header_wrapper {
    font-family: 'gtWalsheim';
    display: flex;
    background: #ffffff;
    @include media_query(XS) {
      align-items: flex-start;
      flex-direction: column;
      height: auto;
      padding: 0px;
      margin-bottom: 15px;
    }
    @include media_query(M) {
      align-items: center;
      flex-direction: row;
      height: 110px;
      padding: 0 40px;
      margin-bottom: 0;
    }
    &.lp_header_wrapper {
      @include media_query(M) {
        // height: auto;
        height: 125px;
      }
      @include media_query(L) {
        height: 75px;
      }
      .heading {
        @include media_query(XS) {
          text-align: left;
          margin-bottom: 0;
        }
        @include media_query(M) {
          text-align: left;
        }
      }
    }
    .heading {
      font-family: 'gtWalsheim';
      font-weight: 700;
      color: #2c2c2c;
      //text-transform: capitalize;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media_query(XS) {
        // display: none;
        text-align: center;
        font-size: 24px;
        padding: 0 15px;
        width: 100%;
        margin-bottom: 15px;
      }
      @include media_query(M) {
        // display: inline-block;
        text-align: left;
        font-size: 32px;
        padding: 0;
        // width: auto;
        // width: 300px;
        margin-bottom: 0;
      }
      @include media_query(L) {
        width: auto;
      }
    }
    .planiText {
      font-weight: 400;
      font-family: 'gtWalsheim';
    }
    .heading_xs {
      font-family: 'gtWalsheim';
      @include media_query(XS) {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #2c2c2c;
        font-size: 24px;
        font-weight: 700;
        padding: 0 15px;
        padding-bottom: 15px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        // margin-bottom: 25px;
      }
      @include media_query(M) {
        display: none;
      }
    }
  }
  .btn_main_wrappper {
    display: flex;
    align-items: center;
    @include media_query(XS) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
      justify-content: center;
      min-height: 70px;
      z-index: 2;
      padding: 0 15px;
    }

    @include media_query(M) {
      position: relative;
      min-height: auto;
      box-shadow: none;
      margin-left: auto;
      padding: 0;
    }
    &.btn_xs {
      display: flex;
      @include media_query(S) {
        // display: none;
      }
    }
    &.btn_preview_xs {
      @include media_query(XS) {
        min-height: 100px;
        align-items: flex-start;
        padding-top: 15px;
        display: flex;
        align-items: center;
      }
    }
    &.header_btn_adapt_option {
      @include media_query(XS) {
        min-height: 100px;
      }
      @include media_query(M) {
        height: auto;
      }
      .btn_wrapper {
        .btn {
          @include media_query(XS) {
            margin-bottom: 30px;
          }
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
      }
    }

    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @include media_query(M) {
        justify-content: center;
      }
      &.justify_left {
        justify-content: left;
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        white-space: nowrap;
        margin: 0 6px;
        padding: 0 25px;
        cursor: pointer;
        outline: none;
        min-width: 135px;
        // margin-left: auto;
        @include media_query(M) {
          margin: 0 6px;
          min-width: 120px;
        }
        &.btn_lg {
          @include media_query(XS) {
            min-width: calc(100% - 145px);
          }
          @include media_query(M) {
            min-width: 120px;
          }
        }
        &:last-child {
          // margin-right: 0;
        }
        &.active {
          background-color: #38539a;
          border: 2px solid transparent;
          color: #fff;
        }
        &.disabled {
          // pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
          &.disabled_btn_1 {
            pointer-events: none;
            background-color: #ffffff;
            border: 2px solid #d9d9d9;
          }
          // &.disabled_btn_2{

          // }
        }
        &.m_0 {
          margin: 0;
        }
      }
      @include media_query(XS) {
        width: 100%;
      }
      @include media_query(M) {
      }
    }
  }
  .progress_container {
    position: relative;
    z-index: 2;
  }
  .progress_bar_wrapper {
    display: flex;
    padding: 0 15px 15px;
    position: relative;
    overflow-x: auto;
    background-color: #ffffff;
    @include media_query(M) {
      padding: 0 40px 15px;
    }
    .course_section {
      display: flex;
      align-items: center;
      margin-right: 35px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.selected {
        .txt {
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #38539a;
          white-space: nowrap;
        }
        .icon_wrapper {
          background-color: #38539a;
          border-radius: 40% 60% 40% 60%;

          .home_icon {
            background-image: url(../../../styles/images/selected_home_icon.svg);
          }
          .course_addition_icon {
            width: 19px;
            height: 21px;
            background-image: url(../../../styles/images/course-addition-selected.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
          .course_sequencing_icon {
            width: 25px;
            height: 21px;
            background-image: url(../../../styles/images/course-sequencing-selected.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
          .user_grp_icon {
            width: 25px;
            height: 20px;
            background-image: url(../../../styles/images/users_icon.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
          .tag_icon {
            background-image: url(../../../styles/images/selected_tag_icon.svg);
          }
          .publish_icon {
            width: 25px;
            height: 21px;
            background-image: url(../../../styles/images/publish-review-selected.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .icon_wrapper {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        cursor: pointer;
        .home_icon {
          width: 25px;
          height: 18px;
          background-image: url(../../../styles/images/gray_home_icon.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
        .course_addition_icon {
          width: 19px;
          height: 20px;
          background-image: url(../../../styles/images/files_gray_icon.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
        .course_sequencing_icon {
          width: 25px;
          height: 21px;
          background-image: url(../../../styles/images/folder_gray.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
        .user_grp_icon {
          width: 25px;
          height: 21px;
          background-image: url(../../../styles/images/user_gray_icon.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
        .tag_icon {
          width: 25px;
          height: 21px;
          background-image: url(../../../styles/images/category_tag_icon.svg);
          background-size: cover;
          background-repeat: no-repeat;
        }
        .publish_icon {
          width: 25px;
          height: 21px;
          background-image: url(../../../styles/images/spec_gray.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .txt {
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #707070;
        white-space: nowrap;
      }
    }
  }
  .progress_bar {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 6px;
    background-color: #e4e4e4;
    width: 100%;
    z-index: 1;
    .unfilled_bar {
      @include media_query(L) {
        width: 50%;
        height: 6px;
        background-color: #38539a;
      }
    }
    .fullfilled_bar_1 {
      @include media_query(XS) {
        width: 68%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(M) {
        width: 38%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(L) {
        width: 20%;
        height: 6px;
        background-color: #38539a;
      }
    }
    .fullfilled_bar_2 {
      @include media_query(XS) {
        width: 86%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(M) {
        width: 69%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(L) {
        width: 35%;
        height: 6px;
        background-color: #38539a;
      }
    }
    .fullfilled_bar_3 {
      @include media_query(XS) {
        width: 90%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(M) {
        width: 90%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(M) {
        width: 85%;
        height: 6px;
        background-color: #38539a;
      }
      @include media_query(L) {
        width: 50%;
        height: 6px;
        background-color: #38539a;
      }
    }
    .fullfilled_bar {
      width: 100%;
      height: 6px;
      background-color: #38539a;
    }
  }
  /* width */
  ::-webkit-scrollbar {
    height: 0px;
    // display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}
