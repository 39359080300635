@import '../../../styles/common/media_queries';

.instance_status {
  width: 100px;
  height: 25px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  font-family: 'gtWalsheim';
  &.live_sts {
    color: #107f47;
    background-color: #f0fae5;
  }
  &.draft_sts {
    color: #a36a00;
    background-color: #fff9f0;
  }
  &.offline_sts {
    color: #62625a;
    background-color: #f0f0f0;
  }
  &.go_live_sts {
    color: #38539a;
    background-color: #f1f2ff;
  }
  &.archive_sts {
    color: #d91734;
    background-color: #fdf4f4;
  }
}

.tbl_data {
  display: flex;
  align-items: center;
  .txt {
    font-family: 'gtWalsheim';
    font-size: 16px;
    color: #2c2c2c;
    @include media_query(S) {
      font-weight: 500;
    }
  }
  .pl_12 {
    padding-left: 12px;
  }
  .user_icon {
    width: 20px;
    height: 16px;
    background-image: url(../../../styles/images/users.svg);
    background-repeat: no-repeat;
  }
  .books_gray {
    width: 14px;
    height: 16px;
    background-image: url(../../../styles/images/books-gray.svg);
    background-repeat: no-repeat;
  }
  .files_gray {
    width: 18px;
    height: 16px;
    background-image: url(../../../styles/images/files-gray.svg);
    background-repeat: no-repeat;
  }
}
.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  height: 24px;
  border-radius: 4px;
  font-family: 'gtWalsheim';
  font-size: 15px;
  font-weight: 500;
  //margin-right: 12px;
  padding: 0 10px;
  @include media_query(XS) {
    margin-bottom: 0;
  }
  @include media_query(M) {
    margin-bottom: 8px;
  }
  @include media_query(L) {
    margin-bottom: 0;
  }
}
.live_card {
  .highlight {
    background: #f0fae5;
    color: #107f47 !important;
  }
}
.offline_card {
  .highlight {
    background: #f0f0f0;
    color: #707070 !important;
  }
}
.archive {
  .highlight {
    background: #fdf4f4;
    color: #d91734 !important;
  }
}
.draft_card {
  .highlight {
    background: #fff9f0;
    color: #a36a00 !important;
  }
}
