.checkbox_wrapper {
  position: relative;
}
.styled_checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  z-index: 1;
  & + label {
    font-family: 'gtWalsheim';
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0;
  }

  // Box.
  & + label:before {
    content: '';
    content: url(../../../styles/images/white_tick.svg);
    margin-right: 10px;
    // display: inline-block;
    display: inline;
    vertical-align: text-top;
    padding: 0px 2px;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #2c2c2c;
    border-radius: 4px;
  }

  // Box hover
  // &:hover + label:before {
  //   background: #2c2c2c;
  //   border-radius: 4px;
  //   border: 1px solid #949494;
  // }

  // Box focus
  &:focus + label:before {
    //   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    border: 2px solid #2c2c2c;
    border-radius: 4px;
  }

  // Box checked
  &:checked + label:before {
    background: #2c2c2c;
    border: 2px solid #2c2c2c;
    border-radius: 4px;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
    content: '';
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    // content: '';
    // content: url(../../../styles/images/white_tick.svg);
    position: absolute;
    left: 5px;
    top: 9px;
    // background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
      4px -8px 0 white;
    // transform: rotate(45deg);
  }
}
