@import '../../../styles/common/media_queries';

body {
  background: #f5f5f5;
}

.card {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
  height: 225px;
  overflow: hidden;
  width: 200px;
  margin: 20px auto;

  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 225px;
    @include media_query(XS) {
      height: 110px;
    }
    @include media_query(M) {
      height: 225px;
    }
    width: 100%;
  }

  .p-32 {
    padding: 32px;
  }

  .title_line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .content_line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.m_t_24 {
  margin-top: 24px;
}

// @import '../../../styles/common/media_queries';

// .br {
//   border-radius: 8px;
// }
// .w80 {
//   width: 80%;
// }
// .card {
//   border: 2px solid #fff;
//   box-shadow: 0px 0px 10px 0 #a9a9a9;
//   padding: 30px 40px;
//   width: 80%;
//   margin: 50px auto;
// }
// .wrapper {
//   width: 0px;
//   animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
// }

// .comment {
//   height: 25px;
//   background: #777;
//   margin-top: 55px;
// }

// @keyframes fullView {
//   100% {
//     width: 100%;
//   }
// }

// .animate {
//   animation: shimmer 2s infinite linear;
//   background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
//   background-size: 1000px 100%;
// }

// @keyframes shimmer {
//   0% {
//     background-position: -1000px 0;
//   }
//   100% {
//     background-position: 1000px 0;
//   }
// }

.card_holder {
  margin-bottom: 20px;
  z-index: 1;
  @include media_query(XS) {
    flex: 100%;
    max-width: 100%;
    padding: 0;
    //margin-bottom: 24px;
  }
  @include media_query(M) {
    flex: 50%;
    max-width: 50%;
    padding: 0 12px;
  }
  @include media_query(L) {
    flex: 20%;
    max-width: 20%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    font-family: 'gtWalsheim';
    z-index: 1;
    @include media_query(XS) {
      height: 110px;
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 12px 12px 0 0;
      margin: 0px;
    }
    @include media_query(M) {
      height: 225px;
      display: block;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.12);
      border-radius: 12px !important;
      padding-bottom: 0;
    }
    @include media_query(L) {
      height: 225px;
    }
    &.selected {
      .checked_card_wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 115px;
        height: 40px;
        z-index: 1;
        background: #f1f2ff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .checked_box {
          width: 18px;
          height: 18px;
          background-image: url(../../../styles/images/checkbox_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 12px;
        }
        .text {
          font-size: 16px;
          font-weight: 700;
          color: #38539a;
          line-height: 21px;
        }
      }
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.25);
    }
    .popup_btn_wrapper {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      cursor: pointer;

      .course_type_icon {
        background-repeat: no-repeat;
        background-size: contain;
        // margin-right: 8px;
        // margin-top: 5px;
        &.gen_visiblity_icon {
          width: 14px;
          height: 18px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/gen_visibility_filled.svg);
        }
        &.qstart_icon {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/qstart_icon_filled.svg);
        }
        &.req_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/req_icon_filled.svg);
        }
        &.recom_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/recom_icon_filled.svg);
        }
      }
      .course_type_popup {
        width: 221px;
        height: 240px;
        background-color: #ffffff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 12px;
        position: absolute;
        left: 0px;
        top: 40px;
        padding: 16px 14px 16px 14px;
        z-index: 3;
        display: none;
        @include media_query(M) {
          display: block;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
          padding-bottom: 12px;
          border-bottom: 1px solid #e4e4e4;
          margin-bottom: 20px;
        }
        .course_types {
          .item_wrapper {
            display: flex;
            align-items: center;
            max-width: 100%;
            margin-bottom: 16px;
            overflow: hidden;
            &:last-child {
              margin-bottom: 0;
            }
            .radio_btn_wrapper {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .item {
              font-family: 'gtWalsheim';
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              color: #2c2c2c;
              margin-right: 8px;
              @include media_query(XS) {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .course_type_icon {
              background-repeat: no-repeat;
              background-size: contain;
              // margin-right: 8px;
              // margin-top: 5px;
              &.gen_visiblity_icon {
                width: 14px;
                height: 18px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/gen_visi_icon.svg);
              }
              &.qstart_icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/qstart_icon.svg);
              }
              &.req_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/req_icon.svg);
              }
              &.recom_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/recom_icon.svg);
              }
            }
            &.selected {
              .course_type_icon {
                &.gen_visiblity_icon {
                  background-image: url(../../../styles/images/gen_visibility_filled.svg);
                }
                &.qstart_icon {
                  background-image: url(../../../styles/images/qstart_icon_filled.svg);
                }
                &.req_icon {
                  background-image: url(../../../styles/images/req_icon_filled.svg);
                }
                &.recom_icon {
                  background-image: url(../../../styles/images/recom_icon_filled.svg);
                }
              }
            }
          }
        }
      }
    }
    .uncheck_card_wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 36px;
      height: 36px;
      z-index: 1;
      background-color: #ffffff;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      border-radius: 12px 0px;
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      justify-content: center;

      .unchecked_box {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/unselectedCheck.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .checked_card_wrapper {
      display: none;
    }

    .card_top {
      position: relative;
      border-radius: 12px !important;
      @include media_query(XS) {
        width: 95px;
        height: 100%;
      }
      @include media_query(M) {
        width: 100%;
        height: 130px;
        padding: 0;
      }
      .view_details {
        display: none;
      }
      .img_wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
        .img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          @include media_query(M) {
            // border-radius: 0;
            border-radius: 12px 12px 0px 0px !important;
          }
        }
      }
    }
    .card_bottom {
      @include media_query(XS) {
        width: calc(100% - 95px);
        padding: 10px;
        padding-top: 0;
        padding-right: 25px;
      }
      @include media_query(M) {
        width: 100%;
        padding: 8px 8px 12px 8px;
      }
      .status_wrapper {
        margin-bottom: 8px;
        display: flex;

        @include media_query(M) {
          display: flex;
          align-items: center;
        }

        .text_wrapper {
          display: flex;
          align-items: center;
          @include media_query(M) {
          }
          .draft_icon {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/draft_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
            display: block;
            @include media_query(M) {
              display: none;
            }
          }
          .archive_cal_icon {
            width: 18px;
            height: 15px;
            background-image: url(../../../styles/images/archive_box_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .offline_cal_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/offline_cal_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .calendar_icon {
            width: 15px;
            height: 18px;
            background-image: url(../../../styles/images/calender_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .date_text {
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 400;
            color: #707070;
            line-height: 1;
          }
        }
        &.draft_cd {
          .date_text {
            // display: none;
          }
        }
      }

      .description {
        font-family: 'gtWalsheim';
        font-size: 14px;
        font-weight: 200;
        color: #2c2c2c;
        max-height: 50px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 95%;
      }
    }
  }
}
.card_holder_learning_path {
  margin-bottom: 20px;
  z-index: 1;
  height: 115px;
  @include media_query(XS) {
    flex: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 24px;
  }
  @include media_query(M) {
    flex: 50%;
    max-width: 100%;
    padding: 0 12px;
  }
  @include media_query(L) {
    flex: 20%;
    max-width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    background-color: #fff;
    height: 115px;
    cursor: pointer;
    position: relative;
    font-family: 'gtWalsheim';
    z-index: 1;
    @include media_query(XS) {
      height: 95px;
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 12px 12px 0 0;
    }
    @include media_query(M) {
      height: 115px;
      display: block;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.12);
      border-radius: 12px !important;
      padding-bottom: 0;
    }
    @include media_query(L) {
      height: 115px;
    }
    &.selected {
      .checked_card_wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 115px;
        height: 40px;
        z-index: 1;
        background: #f1f2ff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .checked_box {
          width: 18px;
          height: 18px;
          background-image: url(../../../styles/images/checkbox_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 12px;
        }
        .text {
          font-size: 16px;
          font-weight: 700;
          color: #38539a;
          line-height: 21px;
        }
      }
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.25);
    }
    .popup_btn_wrapper {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      cursor: pointer;

      .course_type_icon {
        background-repeat: no-repeat;
        background-size: contain;
        // margin-right: 8px;
        // margin-top: 5px;
        &.gen_visiblity_icon {
          width: 14px;
          height: 18px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/gen_visibility_filled.svg);
        }
        &.qstart_icon {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/qstart_icon_filled.svg);
        }
        &.req_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/req_icon_filled.svg);
        }
        &.recom_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/recom_icon_filled.svg);
        }
      }
      .course_type_popup {
        width: 221px;
        height: 240px;
        background-color: #ffffff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 12px;
        position: absolute;
        left: 0px;
        top: 40px;
        padding: 16px 14px 16px 14px;
        z-index: 3;
        display: none;
        @include media_query(M) {
          display: block;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
          padding-bottom: 12px;
          border-bottom: 1px solid #e4e4e4;
          margin-bottom: 20px;
        }
        .course_types {
          .item_wrapper {
            display: flex;
            align-items: center;
            max-width: 100%;
            margin-bottom: 16px;
            overflow: hidden;
            &:last-child {
              margin-bottom: 0;
            }
            .radio_btn_wrapper {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .item {
              font-family: 'gtWalsheim';
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              color: #2c2c2c;
              margin-right: 8px;
              @include media_query(XS) {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .course_type_icon {
              background-repeat: no-repeat;
              background-size: contain;
              // margin-right: 8px;
              // margin-top: 5px;
              &.gen_visiblity_icon {
                width: 14px;
                height: 18px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/gen_visi_icon.svg);
              }
              &.qstart_icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/qstart_icon.svg);
              }
              &.req_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/req_icon.svg);
              }
              &.recom_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/recom_icon.svg);
              }
            }
            &.selected {
              .course_type_icon {
                &.gen_visiblity_icon {
                  background-image: url(../../../styles/images/gen_visibility_filled.svg);
                }
                &.qstart_icon {
                  background-image: url(../../../styles/images/qstart_icon_filled.svg);
                }
                &.req_icon {
                  background-image: url(../../../styles/images/req_icon_filled.svg);
                }
                &.recom_icon {
                  background-image: url(../../../styles/images/recom_icon_filled.svg);
                }
              }
            }
          }
        }
      }
    }
    .uncheck_card_wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 36px;
      height: 36px;
      z-index: 1;
      background-color: #ffffff;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      border-radius: 12px 0px;
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      justify-content: center;

      .unchecked_box {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/unselectedCheck.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .checked_card_wrapper {
      display: none;
    }

    .card_top {
      position: relative;
      border-radius: 12px !important;
      @include media_query(XS) {
        width: 95px;
        height: 100%;
      }
      @include media_query(M) {
        width: 100%;
        height: 130px;
        padding: 0;
      }
      .view_details {
        display: none;
      }
      .img_wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
        .img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          @include media_query(M) {
            // border-radius: 0;
            border-radius: 12px 12px 0px 0px !important;
          }
        }
      }
    }
    .card_bottom {
      @include media_query(XS) {
        width: calc(100% - 95px);
        padding: 10px;
        padding-top: 0;
        padding-right: 25px;
      }
      @include media_query(M) {
        width: 100%;
        padding: 8px 8px 12px 8px;
      }
      .status_wrapper {
        margin-bottom: 8px;
        display: flex;

        @include media_query(M) {
          display: flex;
          align-items: center;
        }

        .text_wrapper {
          display: flex;
          align-items: center;
          @include media_query(M) {
          }
          .draft_icon {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/draft_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
            display: block;
            @include media_query(M) {
              display: none;
            }
          }
          .archive_cal_icon {
            width: 18px;
            height: 15px;
            background-image: url(../../../styles/images/archive_box_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .offline_cal_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/offline_cal_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .calendar_icon {
            width: 15px;
            height: 18px;
            background-image: url(../../../styles/images/calender_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .date_text {
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 400;
            color: #707070;
            line-height: 1;
          }
        }
        &.draft_cd {
          .date_text {
            // display: none;
          }
        }
      }

      .description {
        font-family: 'gtWalsheim';
        font-size: 14px;
        font-weight: 200;
        color: #2c2c2c;
        max-height: 50px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 95%;
      }
    }
  }
}
.card_holder_30 {
  margin-bottom: 20px;
  z-index: 1;
  @include media_query(XS) {
    flex: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 24px;
  }
  @include media_query(M) {
    flex: 50%;
    max-width: 50%;
    padding: 0 12px;
  }
  @include media_query(L) {
    flex: 33.33%;
    max-width: 33.33%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    font-family: 'gtWalsheim';
    z-index: 1;
    @include media_query(XS) {
      height: 110px;
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 12px 12px 0 0;
    }
    @include media_query(M) {
      height: 225px;
      display: block;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.12);
      border-radius: 12px !important;
      padding-bottom: 0;
    }
    @include media_query(L) {
      height: 225px;
    }
    &.selected {
      .checked_card_wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 115px;
        height: 40px;
        z-index: 1;
        background: #f1f2ff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .checked_box {
          width: 18px;
          height: 18px;
          background-image: url(../../../styles/images/checkbox_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 12px;
        }
        .text {
          font-size: 16px;
          font-weight: 700;
          color: #38539a;
          line-height: 21px;
        }
      }
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.25);
    }
    .popup_btn_wrapper {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      cursor: pointer;

      .course_type_icon {
        background-repeat: no-repeat;
        background-size: contain;
        // margin-right: 8px;
        // margin-top: 5px;
        &.gen_visiblity_icon {
          width: 14px;
          height: 18px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/gen_visibility_filled.svg);
        }
        &.qstart_icon {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/qstart_icon_filled.svg);
        }
        &.req_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/req_icon_filled.svg);
        }
        &.recom_icon {
          width: 18px;
          height: 16px;
          flex-shrink: 0;
          background-image: url(../../../styles/images/recom_icon_filled.svg);
        }
      }
      .course_type_popup {
        width: 221px;
        height: 240px;
        background-color: #ffffff;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 12px;
        position: absolute;
        left: 0px;
        top: 40px;
        padding: 16px 14px 16px 14px;
        z-index: 3;
        display: none;
        @include media_query(M) {
          display: block;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
          padding-bottom: 12px;
          border-bottom: 1px solid #e4e4e4;
          margin-bottom: 20px;
        }
        .course_types {
          .item_wrapper {
            display: flex;
            align-items: center;
            max-width: 100%;
            margin-bottom: 16px;
            overflow: hidden;
            &:last-child {
              margin-bottom: 0;
            }
            .radio_btn_wrapper {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .item {
              font-family: 'gtWalsheim';
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              color: #2c2c2c;
              margin-right: 8px;
              @include media_query(XS) {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .course_type_icon {
              background-repeat: no-repeat;
              background-size: contain;
              // margin-right: 8px;
              // margin-top: 5px;
              &.gen_visiblity_icon {
                width: 14px;
                height: 18px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/gen_visi_icon.svg);
              }
              &.qstart_icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/qstart_icon.svg);
              }
              &.req_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/req_icon.svg);
              }
              &.recom_icon {
                width: 18px;
                height: 16px;
                flex-shrink: 0;
                background-image: url(../../../styles/images/recom_icon.svg);
              }
            }
            &.selected {
              .course_type_icon {
                &.gen_visiblity_icon {
                  background-image: url(../../../styles/images/gen_visibility_filled.svg);
                }
                &.qstart_icon {
                  background-image: url(../../../styles/images/qstart_icon_filled.svg);
                }
                &.req_icon {
                  background-image: url(../../../styles/images/req_icon_filled.svg);
                }
                &.recom_icon {
                  background-image: url(../../../styles/images/recom_icon_filled.svg);
                }
              }
            }
          }
        }
      }
    }
    .uncheck_card_wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 36px;
      height: 36px;
      z-index: 1;
      background-color: #ffffff;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      border-radius: 12px 0px;
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      justify-content: center;

      .unchecked_box {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/unselectedCheck.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .checked_card_wrapper {
      display: none;
    }

    .card_top {
      position: relative;
      border-radius: 12px !important;
      @include media_query(XS) {
        width: 95px;
        height: 100%;
      }
      @include media_query(M) {
        width: 100%;
        height: 130px;
        padding: 0;
      }
      .view_details {
        display: none;
      }
      .img_wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
        .img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          @include media_query(M) {
            // border-radius: 0;
            border-radius: 12px 12px 0px 0px !important;
          }
        }
      }
    }
    .card_bottom {
      @include media_query(XS) {
        width: calc(100% - 95px);
        padding: 10px;
        padding-top: 0;
        padding-right: 25px;
      }
      @include media_query(M) {
        width: 100%;
        padding: 8px 8px 12px 8px;
      }
      .status_wrapper {
        margin-bottom: 8px;
        display: flex;

        @include media_query(M) {
          display: flex;
          align-items: center;
        }

        .text_wrapper {
          display: flex;
          align-items: center;
          @include media_query(M) {
          }
          .draft_icon {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/draft_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
            display: block;
            @include media_query(M) {
              display: none;
            }
          }
          .archive_cal_icon {
            width: 18px;
            height: 15px;
            background-image: url(../../../styles/images/archive_box_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .offline_cal_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/offline_cal_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .calendar_icon {
            width: 15px;
            height: 18px;
            background-image: url(../../../styles/images/calender_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            margin-right: 4px;
          }
          .date_text {
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 400;
            color: #707070;
            line-height: 1;
          }
        }
        &.draft_cd {
          .date_text {
            // display: none;
          }
        }
      }

      .description {
        font-family: 'gtWalsheim';
        font-size: 14px;
        font-weight: 200;
        color: #2c2c2c;
        max-height: 50px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 95%;
      }
    }
  }
}
.card_holder_landing_page {
  margin-bottom: 20px;
  @include media_query(XS) {
    flex: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 16px;
  }
  @include media_query(M) {
    flex: 50%;
    max-width: 50%;
    padding: 0 12px;
  }
  @include media_query(L) {
    flex: 33.3%;
    max-width: 33.3%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    background-color: #fff;
    cursor: pointer;

    @include media_query(XS) {
      height: 110px;
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 12px 12px 0 0;
    }
    @include media_query(M) {
      height: 128px;
      display: flex;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      border-radius: 12px;
      padding-bottom: 0;
    }
    @include media_query(L) {
    }
  }
}
