@import '../../../styles/common/media_queries';

.toggle_section_wapper {
  position: relative;
  display: none;
  width: 120px;
  height: 35px;
  margin-left: auto;
  @include media_query(M) {
    display: flex;
    align-items: center;
  }
}
.toggle_wrapper {
  display: flex;
  width: 120px;
  height: 32px;
  padding: 1px;
  margin-left: auto;
  border: 1px solid #2c2c2c;
  border-radius: 24px 24px 24px 24px;
  @include media_query(XS) {
    display: none;
  }
  @include media_query(M) {
    display: flex;
  }
  .card_radius {
    border-radius: 20px 0 0 20px;
  }
  .view_radius {
    border-radius: 0 20px 20px 0;
  }

  .toggle_section {
    position: relative;
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: calc(100% + 2px);
    // border: 1px solid #2c2c2c;
    cursor: pointer;

    &.card_selected,
    &.view_selected {
      background-color: #2c2c2c;
      height: calc(100% + 2px);
      .card_view {
        background-size: contain;
        background-image: url(../../../styles/images/card-view-selected.svg);
      }
      .list_view {
        background-size: contain;
        background-image: url(../../../styles/images/list-view-selected.svg);
      }
    }
    &.card_selected {
      left: -1px;
      border-radius: 24px 24px 24px 24px;
    }
    &.view_selected {
      right: -1px;
      border-radius: 24px 24px 24px 24px;
    }
    .card_view {
      width: 20px;
      height: 20px;
      background-image: url(../../../styles/images/card-view.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
    .list_view {
      width: 20px;
      height: 16px;
      background-image: url(../../../styles/images/list-view.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
