@import '../../../styles/common/media_queries';

.info_btn_wrapper {
  position: relative;

  &.isClicked {
    .course_type_read_banner {
      display: block;
    }
  }
  .info_btn {
    width: 20px;
    height: 20px;
    background-image: url(../../../styles/images/info_button.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .course_type_read_banner {
    display: none;
    position: absolute;
    top: 28px;
    right: -15px;
    width: 180px;
    min-height: 145px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px 5px 16px 16px;
    box-shadow: 0px 2px 16px rgb(44 44 44 / 10%);
    z-index: 1;
    .tooltip {
      position: absolute;
      top: -8px;
      right: 18px;
      width: 15px;
      height: 14px;
      background-image: url(../../../styles/images/tooltip_white_icon.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
    .title {
      font-family: 'gtWalsheim';
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #2c2c2c;
      margin-bottom: 10px;
      @include media_query(M) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .course_types {
      .banner_details {
        max-width: 100%;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .item {
          font-family: 'gtWalsheim';
          font-size: 14px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
.item_wrapper {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow: hidden;
  &.mt_instances {
    align-items: flex-start;
    .course_type_icon {
      margin-top: 5px;
    }
  }
  .item {
    font-family: 'gtWalsheim';
    font-size: 14px;
    font-weight: 500;
    color: #2c2c2c;
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.course_type_icon {
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;

  &.gen_visiblity_icon {
    width: 14px;
    height: 18px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/gen_visibility_filled.svg);
  }
  &.qstart_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/qstart_icon_filled.svg);
  }
  &.req_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/req_icon_filled.svg);
  }
  &.recom_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/recom_icon_filled.svg);
  }
}
