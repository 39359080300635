@import '../../../styles/common/media_queries';

.search_list_wrapper {
  width: 100%;
}
.dropdown_container {
  position: absolute;
  width: 100%;
  top: 45px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1;
  border: 2px solid #2c2c2c;
  padding: 10px 10px 10px 0;
  .list_wrapper {
    //height: 185px;
    overflow: auto;
    .list {
      font-family: 'gtWalsheim';
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #2c2c2c;
      padding: 0 16px;
      text-transform: capitalize;
      // min-height: 35px;
      cursor: pointer;
      @include media_query(XS) {
        height: auto;
        margin-bottom: 20px;
      }
      @include media_query(M) {
        min-height: 35px;
        margin-bottom: 0;
      }
      &:hover {
        font-family: 'gtWalsheim';
        font-weight: 700;
        color: #38539a;
        background-color: #f4f4f4;
        .img_item {
          width: 16px;
          height: 14px;
          background-image: url(../../../styles/images/trolly_icon.svg);
        }
      }
      &:focus {
        font-family: 'gtWalsheim';
        font-weight: 700;
        color: #38539a;
        background-color: #f4f4f4;
        outline: none;
      }
      .img_item {
        width: 10px;
        height: 17px;
        background-image: url(../../../styles/images/mobile_icon.svg);
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 10px;
      }
      .text_item {
      }
    }
  }
}

.input_arrow_dropdown_wrapper {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  .input_wrapper_and_arrow {
    display: flex;
    width: 120%;
    .input_add {
      width: 120%;
      margin-right: 15px;
    }
    .down_arw_icon_add {
      position: relative;
      transform: translateY(-50%);
      right: 50px;
      top: 20px;
      width: 17px;
      height: 12px;
      background-image: url(../../../styles/images/down_arrow_2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .down_arw_icon_open_add {
      transform: translateY(-50%) rotate(180deg);
      position: relative;
      right: 50px;
      top: 20px;
      width: 17px;
      height: 12px;
      background-image: url(../../../styles/images/down_arrow_2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  .dropdown_container_add {
    position: absolute;
    width: 100%;
    top: 45px;
    right: 0px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    border: 2px solid #2c2c2c;
    padding: 10px 10px 10px 0;
    .list_wrapper {
      //height: 185px;
      overflow: auto;
      .list {
        font-family: 'gtWalsheim';
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #2c2c2c;
        padding: 0 16px;
        text-transform: capitalize;
        // min-height: 35px;
        cursor: pointer;
        @include media_query(XS) {
          height: auto;
          margin-bottom: 20px;
        }
        @include media_query(M) {
          min-height: 35px;
          margin-bottom: 0;
        }
        &:hover {
          font-family: 'gtWalsheim';
          font-weight: 700;
          color: #38539a;
          background-color: #f4f4f4;
          .img_item {
            width: 16px;
            height: 14px;
            background-image: url(../../../styles/images/trolly_icon.svg);
          }
        }
        &:focus {
          font-family: 'gtWalsheim';
          font-weight: 700;
          color: #38539a;
          background-color: #f4f4f4;
          outline: none;
        }
        .img_item {
          width: 10px;
          height: 17px;
          background-image: url(../../../styles/images/mobile_icon.svg);
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 10px;
        }
        .text_item {
        }
      }
    }
  }
}
.dropdown_otr {
  position: relative;
  width: 80%;
  margin-right: 15px;
  .input {
    width: 100%;
    margin-right: 15px;
  }
}
.input_wrapper {
  position: relative;
  display: flex;
  &.input_capitalize {
    // text-transform: capitalize;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: default;
    .down_arw_icon {
      cursor: inherit;
    }
    .input {
      border: 2px solid #d9d9d9;
    }
  }

  .input {
    font-family: 'gtWalsheim';
    width: 100%;
    height: 35px;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #2c2c2c;
    padding: 0 12px;
    display: flex;
    align-items: center;
    // text-transform: capitalize;
    &::placeholder {
      color: #707070;
      font-weight: 200;
    }
    @include media_query(M) {
      font-size: 16px;
      line-height: 21px;
    }
    :active {
      border: 2px solid #38539a;
    }
    :focus {
      border: 2px solid #38539a;
    }
  }
  .textarea {
    width: 100%;
    height: 70px;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    font-family: 'gtWalsheim';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #2c2c2c;
    padding: 10px 12px;
    &::placeholder {
      color: #707070;
      font-weight: 200;
    }
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .down_arw_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 17px;
    height: 12px;
    background-image: url(../../../styles/images/down_arrow_2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .down_arw_icon_disabled {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 17px;
    height: 12px;
    background-image: url(../../../styles/images/disabled_down_arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .down_arw_icon_open {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 10px;
    width: 17px;
    height: 12px;
    background-image: url(../../../styles/images/down_arrow_2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .disabled {
    border: 2px solid #d9d9d9;
    color: #707070;
    .down_arw_icon {
      path {
        fill: #707070;
      }
    }
  }
  .input_disable_div {
    border: 2px solid #d9d9d9;
    background: #ffffff;
  }
  .dropdown_otr {
    position: relative;
    width: 80%;
    margin-right: 15px;
    .input {
      width: 100%;
      margin-right: 15px;
      &.disabled {
        border: 2px solid #d9d9d9;
      }
    }
    input:active {
      border: 2px solid #38539a !important;
    }
    input:focus {
      border: 2px solid #38539a !important;
    }
  }
}
.add_btn {
  font-family: 'gtWalsheim';
  // width: 20%;
  min-width: 115px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #5e5e5e;
  background: #d9d9d9;
  border-radius: 24px;
  cursor: pointer;
  .plus_sign {
    width: 12px;
    height: 12px;
    background-image: url(../../../styles/images/plus_icon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
  }
}
.add_btn_enable {
  font-family: 'gtWalsheim';
  // width: 20%;
  min-width: 115px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  background: #2c2c2c;
  border-radius: 24px;
  cursor: pointer;
  .plus_sign {
    width: 12px;
    height: 12px;
    background-image: url(../../../styles/images/add-white.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
    color: #ffffff;

    &path {
      fill: #ffffff;
    }
  }
}
