@import '../../../styles/common/media_queries';

.create_user_wrapper {
  .header_main_wrapper {
    @include media_query(XS) {
      position: relative;
      width: 100%;
      z-index: initial;
    }

    @include media_query(M) {
      position: fixed;
      width: calc(100% - 65px);
      top: 0;
      right: 0;
      z-index: 3;
      margin-bottom: 40px;
    }

    .header_wrapper {
      display: flex;
      background: #ffffff;

      @include media_query(XS) {
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        box-shadow: none;
        padding: 0px;
        margin-bottom: 15px;
      }

      @include media_query(M) {
        align-items: center;
        flex-direction: row;
        height: 110px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        padding: 0 40px;
        margin-bottom: 0;
      }

      .heading {
        font-family: 'gtWalsheim';
        font-weight: 700;
        color: #2c2c2c;
        text-transform: capitalize;
        margin-bottom: 15px;
        @include media_query(XS) {
          display: none;
          font-size: 24px;
          padding: 0 15px;
          width: 100%;
        }
        @include media_query(M) {
          display: inline-block;
          font-size: 32px;
          padding: 0;
          // width: auto;
          width: 300px;
        }
        @include media_query(L) {
          width: auto;
        }
      }

      .heading_xs {
        @include media_query(XS) {
          display: flex;
          justify-content: center;
          width: 100%;
          color: #2c2c2c;
          font-family: 'gtWalsheim';
          font-size: 24px;
          font-weight: 700;
          padding: 0 15px;
          padding-bottom: 15px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          // margin-bottom: 25px;
        }

        @include media_query(M) {
          display: none;
        }
      }
    }
  }

  .btn_wrapper {
    &.btn_xs {
      @include media_query(XS) {
        display: none;
      }

      @include media_query(M) {
        display: flex;
      }
    }

    @include media_query(M) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.justify_left {
      justify-content: left;
    }

    .btn {
      display: flex;
      align-items: center;
      min-width: 120px;
      height: 35px;
      font-family: 'gtWalsheim';
      font-size: 16px;
      font-weight: 700;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 6px;
      padding: 0 30px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }

      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }

      &.m_0 {
        margin: 0;
      }
    }
  }

  .search_main_wrapper {
    position: relative;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }

    &.active {
      .dropdown_container,
      .search_wrapper {
        border: 2px solid #38539a;
      }
    }

    .dropdown_container {
      position: absolute;
      width: 100%;
      top: 45px;
      background-color: #fff;
      border-radius: 10px;
      z-index: 1;
      border: 2px solid #707070;
      padding: 10px 10px 10px 0;

      .list_wrapper {
        height: 185px;
        overflow: auto;

        .list {
          display: flex;
          align-items: center;
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          padding: 0 16px;
          min-height: 35px;
          cursor: pointer;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }

  .search_wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    height: 34px;
    width: 21rem;
    padding: 0 15px;

    .icon_wrapper {
      margin-left: auto;

      .search {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/search-black.svg);
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    .search_input {
      background-color: transparent;
      font-family: 'gtWalsheim';
      font-size: 14px;
      font-weight: 500;
      color: #2c2c2c;
      width: 100%;
      border: none;
      outline: none;
      padding-right: 15px;

      &::placeholder {
        font-weight: 400;
        color: #707070;
      }
    }
  }

  .add_icon {
    width: 12px;
    height: 14px;
    background-image: url(../../../styles/images/add-gray.svg);
    background-repeat: no-repeat;
  }

  .create_user_main_area {
    overflow: auto;
    @include media_query(XS) {
      max-height: calc(100vh - 130px);
    }
    @include media_query(M) {
      padding-top: 150px;
      padding-left: 65px;
      max-height: fit-content;
    }

    @include media_query(L) {
      padding-top: 150px;
      padding-left: 0;
      padding-bottom: 60px;
    }
    .heading_xs {
      font-family: 'gtWalsheim';
      font-weight: 700;
      color: #2c2c2c;
      text-transform: capitalize;
      margin-bottom: 15px;
      @include media_query(XS) {
        display: block;
        font-size: 24px;
        padding: 0 15px;
        width: 100%;
      }

      @include media_query(M) {
        display: none;
        font-family: 'gtWalsheim';
        font-size: 32px;
        padding: 0;
        width: auto;
      }
    }

    .inner_wrapper {
      padding: 0 15px;

      @include media_query(M) {
        padding: 0 40px;
      }
    }

    .negative_m_15 {
      margin: 0 -15px;
    }

    .tag_main_wrapper {
      padding: 25px 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      @include media_query(XS) {
        // margin: 0 -15px;
        margin: 0 0;
        margin-bottom: 30px;
      }
      @include media_query(M) {
        margin: 0 0;
        margin-bottom: 40px;
      }
      .row {
        @include media_query(XS) {
          margin: 0 0;
        }
        @include media_query(M) {
          margin: 0 -15px;
        }
        @include media_query(L) {
          margin: 0 -15px;
        }
      }
      .padding_left_xs {
        @include media_query(XS) {
          padding-left: 0;
        }
        @include media_query(M) {
          padding-left: 15px;
        }
      }
      .padding_xs {
        @include media_query(XS) {
          padding: 0 0;
        }
        @include media_query(M) {
          padding: 0 15px;
        }
      }
      .clear_fix {
        clear: both;
        display: inline-block;
        content: '';
      }
      .tag_wrapper {
        &:first-child {
          @include media_query(XS) {
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #e4e4e4;
          }
          @include media_query(M) {
            margin-bottom: 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid #e4e4e4;
          }
          @include media_query(L) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
      .tag_container {
        display: flex;
        align-items: center;
        @include media_query(XS) {
          margin-bottom: 12px;
        }
        @include media_query(M) {
          margin-bottom: 12px;
        }
        // :first-child {
        //   @include media_query(XS) {
        //     padding-left: 0;
        //   }
        //   @include media_query(M) {
        //     padding-left: 15px;
        //   }
        // }

        .btn_wrapper {
          @include media_query(XS) {
            justify-content: center;
          }
          @include media_query(M) {
            justify-content: left;
          }
          .btn {
            @include media_query(XS) {
              margin: auto;
              justify-content: center;
              padding: 0 0;
            }
            @include media_query(M) {
              margin: 0;
              padding: 0 30px;
            }
          }
        }
      }
    }

    .collapsible_wrapper {
      // margin-bottom: 15px;
      .collapsible {
        display: flex;
        align-items: center;
        border-radius: 10px;
        @include media_query(XS) {
          height: auto;
          padding: 0 0;
          background-color: transparent;
        }
        @include media_query(M) {
          height: 60px;
          padding: 0 25px;
          background-color: #f0f0f0;
        }
        .txt {
          @include media_query(XS) {
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
          }
          @include media_query(M) {
            font-family: 'gtWalsheim';
            font-size: 16px;
          }
        }

        .sub_txt {
          @include media_query(XS) {
            font-family: 'gtWalsheim';
            font-size: 14px;
            color: #2c2c2c;
            padding-left: 5px;
          }
          @include media_query(M) {
            font-family: 'gtWalsheim';
            font-size: 16px;
          }
        }
      }

      .content_wrapper {
        @include media_query(XS) {
          padding: 30px 0 0px 0;
        }
        @include media_query(M) {
          padding: 24px 0px 0px 0px;
        }
        @include media_query(L) {
          padding: 24px 25px 0px 25px;
        }

        .top_area_wrapper {
          align-items: center;
          margin-bottom: 15px;
          @include media_query(XS) {
            display: block;
          }
          @include media_query(M) {
            display: flex;
          }
          .search_main_wrapper {
            @include media_query(XS) {
              margin-bottom: 10px;
            }
            @include media_query(M) {
              margin-bottom: 0;
            }
          }
          .txt {
            font-family: 'gtWalsheim';
            font-size: 16px;
            color: #949494;
          }

          .action_wrapper {
            display: flex;
            align-items: center;
            margin-left: auto;

            .audience_grp {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 205px;
              height: 35px;
              background: #38539a;
              border-radius: 24px;
              padding: 0 30px;
              cursor: pointer;

              &.disabled {
                pointer-events: none;
                background-color: #d9d9d9;
                color: #5e5e5e;
                border: 2px solid transparent;

                .txt {
                  color: #5e5e5e;
                }

                .add {
                  background-image: url(../../../styles/images/add-gray.svg);
                }
              }

              .txt {
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                padding-left: 10px;
                text-transform: capitalize;
              }

              .add {
                width: 12px;
                height: 15px;
                background-image: url(../../../styles/images/add-white.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    .tbl_wrapper {
      // margin-bottom: 30px;
      &.tbl_learning_path {
        .tbl_head {
          &:nth-child(1) {
            @include media_query(XS) {
              width: 70%;
            }
            @include media_query(M) {
              width: 53%;
            }
            .txt {
              @include media_query(XS) {
                padding-left: 0px;
              }
              @include media_query(M) {
                padding-left: 15px;
              }
              @include media_query(L) {
                padding-left: 50px;
              }
            }
          }

          &:nth-child(2) {
            @include media_query(XS) {
              display: none;
              width: 20%;
            }
            @include media_query(M) {
              display: block;
              width: 20%;
            }
          }

          &:nth-child(3) {
            @include media_query(XS) {
              width: 15%;
            }
            @include media_query(M) {
              width: 20%;
            }
          }

          &:nth-child(4) {
            justify-content: center;
            @include media_query(XS) {
              width: 15%;
              display: none;
            }
            @include media_query(M) {
              width: 7%;
              display: flex;
            }
          }
        }

        .tbl_row {
          .tbl_data {
            &:nth-child(1) {
              @include media_query(XS) {
                width: 70%;
                padding-left: 0;
              }
              @include media_query(M) {
                width: 53%;
                padding-left: 47px;
              }
              .txt {
                @include media_query(XS) {
                  padding-left: 0px;
                  padding-right: 35px;
                }
                @include media_query(M) {
                  padding-left: 15px;
                }
                @include media_query(L) {
                  padding-left: 50px;
                  padding-right: 64px;
                }
              }
            }

            &:nth-child(2) {
              width: 20%;
              @include media_query(XS) {
                display: none;
              }
              @include media_query(M) {
                display: flex;
              }
            }

            &:nth-child(3) {
              @include media_query(XS) {
                width: 15%;
                justify-content: center;
              }
              @include media_query(M) {
                width: 20%;
                justify-content: left;
              }
              .status_txt {
                font-family: 'gtWalsheim';
                font-size: 14px;
              }
            }

            &:nth-child(4) {
              @include media_query(XS) {
                width: 15%;
                justify-content: flex-end;
              }
              @include media_query(M) {
                width: 7%;
                justify-content: flex-start;
              }
            }
          }
        }
      }

      &.tbl_courses {
        .tbl_head {
          &:nth-child(1) {
            @include media_query(XS) {
              width: 70%;
            }
            @include media_query(M) {
              width: 60%;
            }
            .txt {
              @include media_query(XS) {
                padding-left: 0px;
              }
              @include media_query(M) {
                padding-left: 50px;
              }
            }
          }

          &:nth-child(2) {
            @include media_query(XS) {
              width: 15%;
            }
            @include media_query(M) {
              width: 25%;
            }
          }

          &:nth-child(3) {
            @include media_query(XS) {
              display: none;
              width: 15%;
            }
            @include media_query(M) {
              display: flex;
              width: 15%;
              justify-content: center;
            }
          }
        }

        .tbl_row {
          .tbl_data {
            &:nth-child(1) {
              @include media_query(XS) {
                width: 70%;
                padding-left: 0;
              }
              @include media_query(M) {
                width: 60%;
                padding-left: 47px;
              }
              .txt {
                @include media_query(XS) {
                  padding-left: 0px;
                  padding-right: 35px;
                }
                @include media_query(M) {
                  padding-left: 50px;
                  padding-right: 64px;
                }
              }
            }

            &:nth-child(2) {
              @include media_query(XS) {
                width: 15%;
                justify-content: center;
              }
              @include media_query(M) {
                width: 25%;
                justify-content: left;
              }
            }

            &:nth-child(3) {
              @include media_query(XS) {
                width: 15%;
                justify-content: center;
              }
              @include media_query(M) {
                width: 15%;
                justify-content: center;
              }
            }
          }
        }
      }

      .tbl_header {
        display: flex;
        align-items: center;
        height: 55px;
        background-color: #2c2c2c;
        border-radius: 12px 12px 0px 0px;
        @include media_query(XS) {
          padding: 0 20px;
        }
        @include media_query(M) {
          padding: 0 25px;
        }
        .txt {
          font-family: 'gtWalsheim';
          font-size: 16px;
          color: #fff;
        }

        .filter_main_wrapper {
          position: relative;
          margin-right: 15px;
          @include media_query(XS) {
            display: none;
          }
          @include media_query(M) {
            display: block;
          }
          .icon_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;

            .filter {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/filter-white.svg);
              background-repeat: no-repeat;
              cursor: pointer;
            }

            &.selected {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              background-color: #fafafa;
              border-radius: 50%;

              .filter {
                background-image: url(../../../styles/images/filter-black.svg);
              }
            }
          }
        }

        .tbl_head {
          display: flex;
          align-items: center;
        }
      }

      .tbl_body {
        // max-height: 250px;
        // overflow: auto;
        .tbl_row {
          display: flex;
          align-items: center;
          border: 1px solid #e4e4e4;
          border-top: 0;
          @include media_query(XS) {
            padding: 0 20px;
            min-height: 72px;
          }
          @include media_query(M) {
            padding: 0 25px;
            min-height: 72px;
          }
          &.selected {
            background-color: #f4f4f4;
          }

          .tbl_data {
            display: flex;
            align-items: center;

            &.justify_center {
              justify-content: center;
            }

            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              @include media_query(XS) {
                width: 15px;
                height: 15px;
                border-radius: 50%;
              }
              @include media_query(M) {
                min-width: 95px;
                width: auto;
                height: 25px;
                padding: 0 10px;
                border-radius: 5px;
              }
              &.live {
                @include media_query(XS) {
                  background-color: #107f47;
                }
                @include media_query(M) {
                  background-color: #f0fae5;
                  color: #107f47;
                }
              }

              &.draft {
                @include media_query(XS) {
                  background-color: #a65523;
                }
                @include media_query(M) {
                  background-color: #fff1e8;
                  color: #a65523;
                }
              }

              &.go_offline {
                @include media_query(XS) {
                  background-color: #7f3e3e;
                }
                @include media_query(M) {
                  background-color: #ffe2e2;
                  color: #7f3e3e;
                }
              }

              &.go_live {
                @include media_query(XS) {
                  background-color: #38539a;
                }
                @include media_query(M) {
                  background-color: #f1f2ff;
                  color: #38539a;
                }
              }
            }
            .status_txt {
              @include media_query(XS) {
                display: none;
              }
              @include media_query(M) {
                display: block;
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 500;
              }
            }
            .txt {
              font-family: 'gtWalsheim';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
            }

            .book_icon {
              width: 14px;
              height: 16px;
              background-image: url(../../../styles/images/Book-black.svg);
              background-repeat: no-repeat;
              background-size: contain;
            }

            .remove {
              width: 16px;
              height: 16px;
              background-image: url(../../../styles/images/rounded-delete.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;

              &:hover {
                background-image: url(../../../styles/images/rounded-delete-red.svg);
              }
            }
          }
        }
      }
    }

    .form_wrapper {
      .tag_wrapper {
        @include media_query(M) {
          border-right: none;
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 25px;
          margin-bottom: 25px;
        }

        @include media_query(L) {
          border-right: 1px solid #e4e4e4;
          border-bottom: none;
        }
        // :nth-child(1) {
        //   @include media_query(XS) {
        //     padding: 0 0px 0 0;
        //   }
        //   @include media_query(M) {
        //     padding: 0 15px;
        //   }
        //   .form_field {
        //     @include media_query(XS) {
        //       padding-right: 15px;
        //     }
        //   }
        // }
        // .new_targeter_wrapper {
        //   &.form_field {
        //     @include media_query(XS) {
        //       padding-right: 0;
        //     }
        //     @include media_query(M) {
        //       padding-right: 15px;
        //     }
        //   }
        // }
        &:last-child {
          border-right: none;
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .row_wrapper {
          margin: 0 -6px;
        }

        .p_lr {
          padding-left: 6px;
          padding-right: 6px;
        }

        .filter_main_wrapper {
          position: relative;
          // display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          @include media_query(XS) {
            margin-bottom: 0;
          }
          @include media_query(M) {
            margin-bottom: 12px;
          }

          .btn_wrapper {
            .btn {
              min-width: 100%;
              justify-content: center;
            }
          }

          .dropdown_container {
            position: absolute;
            width: 100%;
            height: 155px;
            top: 45px;
            background-color: #fff;
            border-radius: 10px;
            z-index: 1;
            border: 2px solid #707070;
            overflow: auto;

            .list_wrapper {
              .list {
                display: flex;
                align-items: center;
                font-family: 'gtWalsheim';
                font-size: 16px;
                color: #2c2c2c;
                padding: 0px 16px;
                min-height: 35px;
                cursor: pointer;

                &:hover {
                  font-weight: 700;
                }
              }
            }
          }

          .filter_wrapper {
            position: relative;

            .filter {
              display: flex;
              align-items: center;
              height: 36px;
              padding: 0 12px;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              cursor: pointer;

              &.w_345 {
                width: 345px;
              }

              &.w_185 {
                width: 185px;
              }

              .down_arrow {
                width: 17px;
                height: 12px;
              }

              .txt {
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 500;
                color: #707070;
              }
            }
          }
        }
      }

      .new_targeter_wrapper {
        .new_targeter {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .load_preset {
            margin-left: auto;
            min-width: fit-content;
            @include media_query(XS) {
              display: none;
            }
            @include media_query(M) {
              display: flex;
            }
          }
          .label {
            margin-bottom: 0 !important;
            &.w_50 {
              @include media_query(XS) {
                width: 100%;
              }
              @include media_query(M) {
                width: calc(41.66% - 8px);
              }
            }

            .users {
              margin-left: auto;
            }
          }
        }
        .row_wrapper {
          .filter_wrapper {
            @include media_query(XS) {
              margin-bottom: 10px;
            }
            @include media_query(M) {
              margin-bottom: 10px;
            }
            @include media_query(L) {
              margin-bottom: 0;
            }
          }
        }
      }

      .form_field {
        display: flex;
        flex-direction: column;

        .label {
          display: flex;
          align-items: center;
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 500;
          color: #2c2c2c;
          margin-bottom: 12px;
          text-transform: capitalize;

          .users {
            display: flex;
            align-items: center;
            margin-left: auto;

            .txt {
              font-family: 'gtWalsheim';
              font-size: 18px;
              font-weight: 500;
              color: #2c2c2c;
            }
          }

          .user_icon {
            width: 23px;
            height: 18px;
            background-image: url(../../../styles/images/people.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 10px;
          }
        }

        .input_wrapper {
          .input {
            width: 100%;
            height: 35px;
            border: 2px solid #2c2c2c;
            border-radius: 10px;
            font-family: 'gtWalsheim';
            font-size: 16px;
            font-weight: 500;
            color: #2c2c2c;
            padding: 0 12px;

            &::placeholder {
              font-weight: 400;
              color: #707070;
            }
          }

          .textarea {
            width: 100%;
            height: 70px;
            border: 2px solid #2c2c2c;
            border-radius: 10px;
            font-family: 'gtWalsheim';
            font-size: 16px;
            font-weight: 500;
            color: #2c2c2c;
            padding: 10px 12px;

            &::placeholder {
              font-weight: 400;
              color: #707070;
            }
          }
        }
      }

      .load_preset {
        display: flex;

        &.items_center {
          align-items: center;
        }

        &.direction_col {
          flex-direction: column;
        }

        .flex_center {
          display: flex;
          align-items: center;
        }

        .label {
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 700;
          color: #38539a;
          margin-bottom: 0;
        }
      }
    }

    .arrow_left {
      width: 10px;
      height: 20px;
      background-image: url(../../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .arrow_right {
      width: 7px;
      height: 16px;
      background-image: url(../../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .right_arrow {
      width: 10px;
      height: 20px;
      background-image: url(../../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(270deg);
      cursor: pointer;
    }

    .arrow_down {
      width: 10px;
      height: 20px;
      background-image: url(../../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      transform: rotate(270deg);
      cursor: pointer;
    }

    .down_arrow {
      background-image: url(../../../styles/images/down-arrow-slim.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: auto;
      cursor: pointer;
      @include media_query(XS) {
        width: 15px;
        height: 14px;
      }
      @include media_query(M) {
        width: 23px;
        height: 15px;
      }
      &.isOpen {
        transform: rotate(180deg);
      }
    }
  }

  .pills_main_wrapper {
    @include media_query(XS) {
      padding: 0 0;
    }
    @include media_query(M) {
      padding: 0 15px;
    }
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .ml_auto {
    margin-left: auto;
  }

  .ml_10 {
    margin-left: 10px;
  }

  .mb_25 {
    @include media_query(XS) {
      margin-bottom: 25px;
    }
    @include media_query(M) {
      margin-bottom: 25px;
    }
  }

  .mb_40 {
    margin-bottom: 40px;
  }

  .mb_12 {
    margin-bottom: 12px !important;
  }

  .cursor_p {
    cursor: pointer;
  }
}
