@import '../../../../styles/common/media_queries';

.modal_wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  .edit_image_container {
    width: 100%;
    height: 100%;
    padding: 40px;
    padding-top: 150px;
    // padding-left: 105px;
    background-color: #fff;
    @include media_query(M) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include media_query(L) {
      // padding-left: 0px;
    }
    .header_main_wrapper {
      font-family: 'gtWalsheim';
      display: none;
      // padding-left: 65px;
      @include media_query(M) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 3;
        margin-bottom: 40px;
      }

      .header_wrapper {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        width: 100%;
        height: 110px;

        @include media_query(M) {
          align-items: center;
          flex-direction: row;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          padding: 0 40px;
          margin-bottom: 0;
        }

        .heading {
          font-family: 'gtWalsheim';
          font-weight: 700;
          color: #2c2c2c;
          text-transform: capitalize;
          @include media_query(XS) {
            display: none;
            font-size: 24px;
            padding: 0 15px;
            width: 100%;
          }
          @include media_query(M) {
            display: inline-block;
            font-size: 32px;
            padding: 0;
            // width: auto;
            width: 300px;
          }
          @include media_query(L) {
            width: auto;
          }
        }
        .btn_wrapper {
          min-width: 240px;
          height: 35px;
          &.btn_xs {
            @include media_query(XS) {
              display: none;
            }

            @include media_query(M) {
              display: flex;
            }
          }

          @include media_query(M) {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.justify_left {
            justify-content: left;
          }

          .btn {
            display: flex;
            align-items: center;
            min-width: 120px;
            height: 35px;
            font-family: 'gtWalsheim';
            font-size: 16px;
            font-weight: 700;
            border: 2px solid #2c2c2c;
            background-color: #fff;
            border-radius: 24px;
            margin: 0 6px;
            padding: 0 30px;
            cursor: pointer;

            &:last-child {
              margin-right: 0;
            }

            &.disabled {
              pointer-events: none;
              background-color: #d9d9d9;
              color: #5e5e5e;
              border: 2px solid transparent;
              justify-content: center;
            }
            &.active {
              background-color: #38539a;
              border: 2px solid transparent;
              color: #fff;
              justify-content: center;
            }

            &.m_0 {
              margin: 0;
            }
          }
        }
      }
    }

    // .edit_image_wrapper {
    //   width: 100%;
    //   height: 75%;
    //   padding: 40px;
    //   object-fit: cover;

    //   @include media_query(L) {
    //     height: 100%;
    //   }

    //   .edit_image {
    //     width: 100%; //Should be Image size
    //     height: 100%; //Should be Image size
    //     background-image: url(../../../../styles/images/unsplash_image.svg);
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     object-fit: cover;
    //     background-position: center;
    //   }
    // }
  }

  .edit_image_container img {
    object-fit: contain;
  }

  @include media_query(M) {
    display: flex;
    align-items: center;
  }
}
