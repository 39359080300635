@import '../../../styles/common/media_queries';

.modal_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  .delete_modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: #fff;
    // padding: 20px 30px 15px 30px;
    @include media_query(XS) {
      min-width: 100%;
      min-height: 83%;
      max-width: 100%;
      max-height: 83%;
      margin-bottom: 0;
      padding-top: 35px + 43px;
      border-radius: 20px 20px 0px 0px;
    }
    @include media_query(M) {
      min-width: 500px;
      min-height: 230px;
      max-width: 400px;
      max-height: 500px;
      padding-top: 0;
      margin-bottom: auto;
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 15px;
    }
    .mob_bar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      background-color: #fafafa;
      border-radius: 20px 20px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .line_bar {
        width: 50px;
        height: 3px;
        background-color: #2c2c2c;
      }
      @include media_query(M) {
        display: none;
      }
    }
    .header_wrapper {
      align-items: center;
      height: 72px;
      padding: 20px 40px 20px 32px;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      margin-bottom: 24px;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
      }
      .heading {
        font-family: 'gtWalsheim';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }

    .header_wrapper_xs {
      @include media_query(XS) {
        display: flex;
        padding: 0 16px;
        margin-bottom: 10px;
      }
      @include media_query(M) {
        display: none;
        margin-bottom: 0;
      }
      .heading {
        font-family: 'gtWalsheim';
        font-size: 21px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }

    .close_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: #2c2c2c;
      cursor: pointer;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
      }
      .close_icon {
        width: 10px;
        height: 10px;
        background-image: url(../../../styles/images/close-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;

      .warning_icon {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/warning-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        &.cst_warning {
          @include media_query(XS) {
            position: relative;
            top: 5px;
          }
          @include media_query(M) {
            top: 3px;
          }
        }
      }

      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 700;
          color: #a36a00;
        }

        .sub_heading {
          font-family: 'gtWalsheim';
          font-size: 14px;
          color: #a36a00;
        }

        &.cst_delete {
          .heading {
            font-weight: 500;
            color: #a66400;
          }
          .bold_txt {
            font-weight: 700;
            color: #a66400;
            font-style: italic;
          }
        }
      }
    }

    .form_main_wrapper {
      margin-bottom: 12px;
      @include media_query(XS) {
        padding: 0 16px 0 16px;
      }
      @include media_query(M) {
        padding: 0 24px 0 24px;
      }
      .form_field {
        display: flex;
        flex-direction: column;
        @include media_query(XS) {
          margin-bottom: 16px;
        }
        @include media_query(M) {
          margin-bottom: 24px;
        }
        // &:last-child {
        //   margin-bottom: 0;
        // }
        &.category_dropdown {
          @include media_query(XS) {
            display: flex;
          }
          @include media_query(M) {
            // display: none;
          }

          .select_main_container {
            position: relative;
            .select_dropdown {
              display: flex;
              align-items: center;
              height: 36px;
              border: 3px solid #2c2c2c;
              border-radius: 12px;
              background-color: #fff;
              padding: 0 12px;
              cursor: pointer;
              &.active_dropdown {
                border: 3px solid #38539a;
              }
              .txt {
                font-family: 'gtWalsheim';
                font-weight: 500;
                font-size: 16px;
                color: #2c2c2c;
              }
              .arrow {
                width: 16px;
                height: 11px;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: auto;
                background-image: url(../../../styles/images/down-arrow-slim.svg);
                &.down_arrow {
                  transform: rotate(0deg);
                }
                &.up_arrow {
                  transform: rotate(180deg);
                }
              }
            }
            .dropdown_main_container {
              position: absolute;
              top: 45px;
              left: 0;
              width: 100%;
              max-height: 200px;
              background-color: #fff;
              overflow: auto;
              z-index: 1;
              border: 3px solid #2c2c2c;
              border-radius: 12px;
              &.selected {
                border: 3px solid #38539a;
              }
              .list {
                display: flex;
                align-items: center;
                font-family: 'gtWalsheim';
                color: #2c2c2c;
                font-weight: 500;
                font-size: 16px;
                height: 42px;
                padding: 0 12px;
                cursor: pointer;
                &.selected {
                  font-weight: 700;
                  color: #38539a;
                  background: #f4f4f4;
                  &:first-child {
                    border-radius: 10px 10px 0px 0px;
                  }
                  &:last-child {
                    border-radius: 0px 0px 10px 10px;
                  }
                }
              }
            }
          }
        }
        .label {
          font-family: 'gtWalsheim';
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #2c2c2c;
          margin-bottom: 8px;
          @include media_query(M) {
            font-size: 16px;
            margin-bottom: 12px;
          }
        }
        .label_wrapper {
          font-family: 'gtWalsheim';

          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          @include media_query(M) {
            margin-bottom: 8px;
          }
          .label_text {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #2c2c2c;
            white-space: nowrap;
            line-height: initial;
            @include media_query(XS) {
              font-size: 14px;
            }
            @include media_query(M) {
              font-size: 16px;
            }
          }
          .tag_text {
            font-family: 'gtWalsheim';
            color: #707070;
            line-height: initial;
            @include media_query(XS) {
              font-size: 14px;
            }
            @include media_query(M) {
              font-size: 16px;
            }
          }
          @include media_query(M) {
            font-size: 21px;
          }
        }
        .input_wrapper {
          position: relative;
          &.input_capitalize {
            // text-transform: capitalize;
          }
          .input {
            font-family: 'gtWalsheim';
            width: 100%;
            height: 35px;
            border: 3px solid #2c2c2c; // #D9D9D9
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #2c2c2c;
            padding: 0 12px;
            display: flex;
            align-items: center;
            //text-transform: capitalize;
            &::placeholder {
              color: #707070;
              font-weight: 200;
            }
            &.disabled {
              border: 3px solid #d9d9d9;
              pointer-events: none;
            }
            @include media_query(M) {
              font-size: 16px;
              line-height: 21px;
            }
          }
          .textarea {
            width: 100%;
            height: 70px;
            border: 2px solid #2c2c2c;
            border-radius: 10px;
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #2c2c2c;
            padding: 10px 12px;
            &::placeholder {
              color: #707070;
              font-weight: 200;
            }
            @include media_query(M) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
      .error_wrapper {
        &.form_field {
          .input_wrapper {
            font-family: 'gtWalsheim';
            .input {
              border: 2px solid #d91734;
            }
            .error_icon_red {
              position: absolute;
              top: 25%;
              transform: translateY(-50%);
              right: 12px;
              width: 20px;
              height: 20px;
              background-image: url(../../../styles/images/error_icon_red.svg);
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }

        .error_message {
          font-family: 'gtWalsheim';
          color: #d91734;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          padding: 10px 0;
        }
      }
    }

    .confirm_wrapper {
      padding-bottom: 45px;

      .txt {
        font-family: 'gtWalsheim';
        font-size: 14px;
        color: #2c2c2c;
        margin-bottom: 10px;
      }

      .input_wrapper {
        position: relative;
        margin-bottom: 10px;
        .input {
          width: 100%;
          height: 35px;
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          padding: 0 10px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;

          &::placeholder {
            color: #707070;
          }
          &.error_wrapper {
            border: 2px solid #d91734;
            border-radius: 10px;
          }
        }
      }
    }

    .confirm_msg {
      text-align: center;

      .txt {
        display: inline-block;
        vertical-align: middle;
        font-family: 'gtWalsheim';
        font-size: 16px;
        color: #2c2c2c;
      }

      .bold {
        font-family: 'gtWalsheim';
        font-weight: 700;
      }
    }

    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      @include media_query(XS) {
        padding: 0 16px;
        box-shadow: 0px -2px 6px rgba(44, 44, 44, 0.1);
        height: 68px;
      }
      @include media_query(M) {
        padding: 0 0px;
        box-shadow: none;
        height: auto;
        height: 68px;
      }
      .btn {
        min-width: 120px;
        height: 48px;
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        outline: none;
        border: 3px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px;
        padding: 0 30px;
        cursor: pointer;
        &:nth-child(1) {
          @include media_query(XS) {
            min-width: calc(40% - 6px);
            height: 36px;
          }
          @include media_query(M) {
            height: 48px;
            min-width: 120px;
          }
        }
        &:nth-child(2) {
          @include media_query(XS) {
            min-width: calc(60% - 6px);
            height: 36px;
          }
          @include media_query(M) {
            height: 48px;
            min-width: 120px;
          }
        }
        &.active {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }

        &.save_changes {
          background-color: #2c2c2c;
          border: 3px solid transparent;
          color: #fff;
        }

        &.disabled {
          //pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
        }
      }
    }
  }
}
.error_icon_red {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 20px;
  height: 20px;
  background-image: url(../../../styles/images/error_icon_red.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.error_message {
  color: #d91734;
  font-family: 'gtWalsheim';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
