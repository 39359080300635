/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  height: 100%;
  font-family: 'gtWalsheim';
  font-weight: 300;
  line-height: 1.3;
  font-size: 15px;
}
input,
textarea,
button {
  font-family: 'gtWalsheim';
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

img {
  max-width: 100%;
}
.f-500 {
  font-weight: 500;
}
.icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
.error-lbl {
  position: absolute;
  padding-top: 10px;
  font-size: 14px;
  color: #fc0446;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.align_middle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.display-tbl {
  display: table;
  width: 100%;
  height: 100%;
}
.display-tbl-cell {
  display: table-cell;
  vertical-align: middle;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.47);
  z-index: 17;
  padding: 0;
}
.inline-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 17;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.text-center {
  text-align: center;
}
.no-pd {
  padding: 0px !important;
}
.justify-center-content {
  justify-content: center;
}
.visible-lg {
  display: block;
  // @include media_query(M) {
  //     display: none;
  // }
}
.visible-md {
  display: block;
  // @include media_query(S) {
  //     display: none;
  // }
}
.visible-xs {
  display: none;
  // @include media_query(M) {
  //     display: block;
  // }
}
