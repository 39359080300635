@import '../../../styles/common/media_queries';

.pills_wrapper {
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -6px;
  margin-top: 10px;
  @include media_query(XS) {
    display: flex;
    padding: 0 0;
  }
  @include media_query(M) {
    display: flex;
    // padding: 0 15px;
  }
  .pill {
    align-items: center;
    padding: 0 12px;
    width: max-content;
    background-color: #fff;
    border: 1px solid #38539a;
    border-radius: 24px;
    margin: 6px;
    cursor: default;
    @include media_query(XS) {
      display: inline-flex;
      height: 24px;
      padding: 0 12px !important;
    }
    @include media_query(M) {
      display: flex;
      height: 32px;
    }
    .txt {
      @include media_query(XS) {
        font-family: 'gtWalsheim';
        font-size: 14px;
      }
      @include media_query(M) {
        font-family: 'gtWalsheim';
        font-size: 16px;
      }
      color: #38539a;
      padding: 0 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      // text-transform: capitalize;
    }
    .bold {
      font-family: 'gtWalsheim';
      font-weight: 700;
    }
    .rounded_close {
      width: 16px;
      height: 16px;
      background-image: url(../../../styles/images/rounded-close.svg);
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      margin-left: 5px;
      cursor: pointer;
    }
    &.bg_blue {
      background: #38539a;
      .txt {
        color: #fff;
      }
      .rounded_close {
        background-image: url(../../../styles/images/rounded-close-white.svg);
      }
    }
  }
  .pill_black {
    align-items: center;
    padding: 0 12px;
    width: max-content;
    background-color: #fff;
    border: 1px solid #2c2c2c;
    border-radius: 24px;
    margin: 6px;
    cursor: default;
    @include media_query(XS) {
      display: inline-flex;
      height: 24px;
      padding: 0 12px !important;
    }
    @include media_query(M) {
      display: flex;
      height: 32px;
    }
    .txt {
      @include media_query(XS) {
        font-family: 'gtWalsheim';
        font-size: 14px;
      }
      @include media_query(M) {
        font-family: 'gtWalsheim';
        font-size: 16px;
      }
      color: #2c2c2c;
      padding: 0 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      // text-transform: capitalize;
    }
    .bold {
      font-family: 'gtWalsheim';
      font-weight: 700;
    }
    .rounded_close {
      width: 16px;
      height: 16px;
      background-image: url(../../../styles/images/rounded-close.svg);
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
