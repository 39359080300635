@import '../../styles/common/media_queries';

.content_mgt_wrapper {
  width: 100%;
  .header_main_wrapper {
    @include media_query(M) {
      padding-left: 65px;
    }
    @include media_query(L) {
      position: fixed;
      width: calc(100% - 65px);
      top: 0;
      z-index: 3;
      margin-bottom: 40px;
      padding-left: 0;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      // height: 110px;
      padding: 0 40px;
      @include media_query(XS) {
        min-height: 35px;
        padding-bottom: 15px;
        justify-content: center;
      }
      @include media_query(M) {
        min-height: auto;
        height: 110px;
        padding-bottom: 0;
        justify-content: left;
      }
      .heading {
        font-family: 'gtWalsheim';
        font-weight: 700;
        color: #2c2c2c;
        @include media_query(XS) {
          font-family: 'gtWalsheim';
          font-size: 24px;
        }
        @include media_query(M) {
          font-family: 'gtWalsheim';
          font-size: 32px;
        }
      }
    }
  }
  .search_main_wrapper {
    position: relative;
    margin-right: 15px;
    /* width */
    // @include media_query(XS) {
    //   display: none;
    // }
    @include media_query(XS) {
      display: block;
      width: 100%;
    }
    @include media_query(S) {
      min-width: calc(100% - 51px);
    }
    @include media_query(M) {
      // min-width: fit-content;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }

    &.active {
      .dropdown_container,
      .search_wrapper {
        border: 2px solid #38539a;
      }
    }

    .dropdown_container {
      position: absolute;
      width: 100%;
      top: 45px;
      background-color: #fff;
      border-radius: 10px;
      z-index: 1;
      border: 2px solid #707070;
      padding: 10px 0;
      .no_results {
        padding: 0 15px;
      }
      .list_wrapper {
        max-height: 185px;
        overflow: auto;
        .list {
          display: flex;
          align-items: center;
          padding: 0 16px;
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          cursor: pointer;
          @include media_query(XS) {
            height: auto;
            margin-bottom: 20px;
          }
          @include media_query(M) {
            min-height: 35px;
            margin-bottom: 0;
          }
          &:hover {
            color: #38539a;
            font-family: 'gtWalsheim';
            font-weight: 700;
            background-color: #f4f4f4;
          }
        }
      }
    }
  }
  .search_wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    height: 35px;
    // width: 21rem;
    padding: 0 10px;

    @include media_query(XS) {
      // width: 15.5rem;
      // width: 18rem ;
    }
    @include media_query(M) {
      // width: 16rem ;
    }
    @include media_query(L) {
      // width: 25rem ;
    }
    &.istypingstart {
      .icon_wrapper {
        .search {
          display: none;
        }
        .close_icon {
          display: block;
        }
      }
    }

    // @include media_query(XS) {
    //   // width: 15.5rem;
    //   width: 18rem;
    // }
    // @include media_query(M) {
    //   width: 16rem;
    // }
    // @include media_query(L) {
    //   width: 25rem;
    // }

    .icon_wrapper {
      margin-left: auto;
      .search {
        width: 18px;
        height: 18px;
        background-image: url(../../styles/images/search-black.svg);
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .close_icon {
        width: 12px;
        height: 12px;
        background-image: url(../../styles/images/close.svg);
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }

    .search_input {
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      font-family: 'gtWalsheim';
      color: #2c2c2c;
      width: 100%;
      border: none;
      outline: none;
      padding-right: 15px;
      &::placeholder {
        font-family: 'gtWalsheim';
        font-weight: 200;
        color: #707070;
      }
    }
  }

  .audience_wrapper {
    height: calc(100vh - 185px);
    // overflow-y: auto;
    overflow-y: initial;

    &.is_drawer_open {
      @include media_query(L) {
        height: 100vh;
        overflow: hidden;
      }

      .top_area_wrapper {
        @include media_query(L) {
          width: calc(100% - 500px);
        }
        .search_main_wrapper {
          width: calc(100% - 345px);
          .search_wrapper {
            width: 100% !important;
          }
        }
      }

      .inner_wrapper {
        .content_wrapper {
          @include media_query(L) {
            height: calc(100vh - 225px);
            overflow-y: auto;
          }
          .image_container {
            .flex_item {
              @include media_query(L) {
                flex: 20%;
                max-width: 20%;
              }
            }
            @include media_query(M) {
              // flex-direction: column;
            }
            @include media_query(L) {
              flex-direction: row;
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }

        .table_main_wrapper {
          .tbl_body {
            @include media_query(L) {
              height: calc(100vh - 295px);
              overflow-y: auto;
            }
          }
          .tbl_header {
            .tbl_head {
              &:first-child {
                @include media_query(L) {
                  width: 15%;
                }
              }
            }
            .tbl_head_wrapper {
              width: 85%;
            }
          }
          .tbl_body {
            .tbl_row_wrapper {
              .tbl_row {
                .tbl_data {
                  &:first-child {
                    @include media_query(L) {
                      width: 15%;
                    }
                  }
                }
                .tbl_data_wrapper {
                  @include media_query(L) {
                    width: 85%;
                  }
                }
              }
            }
          }
        }
        .img_drawer_container {
          .img_drawer_wrapper {
            display: block;
            width: 100%;

            @include media_query(M) {
              width: 500px;
            }
            @include media_query(L) {
              position: relative;
              top: -50px;
              padding-top: 0;
            }
          }
        }
      }
    }

    .top_area_wrapper {
      display: flex;
      align-items: center;
      @include media_query(XS) {
        margin: 0 15px;
        margin-bottom: 32px;
      }
      @include media_query(M) {
        // display: flex;
        margin: 0;
        margin-bottom: 15px;
        padding: 0 40px;
      }

      @include media_query(L) {
        width: 100%;
      }

      .txt {
        font-family: 'gtWalsheim';
        font-size: 16px;
        color: #949494;
      }
      .sort_by_wrapper {
        order: 1;
        @include media_query(XS) {
          display: flex;
          align-items: center;
          margin-left: auto;
        }
        @include media_query(M) {
          margin-left: 0;
          order: 0;
        }
        .txt {
          @include media_query(XS) {
            display: none;
            font-family: 'gtWalsheim';
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
            text-transform: capitalize;
          }
          @include media_query(M) {
            display: none;
          }
        }
        .icon_wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 36px;
          height: 36px;
          @include media_query(XS) {
            background-color: #f4f4f4;
            border-radius: 50%;
          }
          @include media_query(M) {
            background-color: #f4f4f4;
            border-radius: 50%;
            margin-right: 10px;
          }
          .filter {
            width: 18px;
            height: 19px;
            background-image: url(../../styles/images/filter-black.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            background-size: contain;
          }
          &.selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: #fafafa;
            border-radius: 50%;
            .filter {
              background-image: url(../../styles/images/filter-black.svg);
            }
          }
        }
      }
      .toggle_wrapper {
        display: flex;
        width: 120px;
        height: 32px;
        padding: 1px;
        margin-left: auto;
        border: 1px solid #2c2c2c;
        border-radius: 24px 24px 24px 24px;
        @include media_query(XS) {
          display: none;
        }
        @include media_query(M) {
          display: flex;
        }
        .card_radius {
          border-radius: 20px 0 0 20px;
        }
        .view_radius {
          border-radius: 0 20px 20px 0;
        }

        .toggle_section {
          position: relative;
          top: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: calc(100% + 2px);
          // border: 1px solid #2c2c2c;
          cursor: pointer;

          &.card_selected,
          &.view_selected {
            background-color: #2c2c2c;
            height: calc(100% + 2px);
            .card_view {
              background-size: contain;
              background-image: url(../../styles/images/card-view-selected.svg);
            }
            .list_view {
              background-size: contain;
              background-image: url(../../styles/images/list-view-selected.svg);
            }
          }
          &.card_selected {
            left: -1px;
            border-radius: 24px 24px 24px 24px;
          }
          &.view_selected {
            right: -1px;
            border-radius: 24px 24px 24px 24px;
          }
          .card_view {
            width: 20px;
            height: 20px;
            background-image: url(../../styles/images/card-view.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
          .list_view {
            width: 20px;
            height: 16px;
            background-image: url(../../styles/images/list-view.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .btn_wrapper {
        // display: flex;
        align-items: center;
        margin-left: 16px;
        @include media_query(M) {
          display: flex;
          position: relative;
          height: auto;
          padding: 0 0;
        }
        &.btn_wrapper_xs {
          position: relative;
          margin-left: 16px;
          height: auto;
          width: auto;
          padding: 0 0;
          @include media_query(S) {
            position: fixed;
            display: flex;
            align-items: center;
            background-color: #fff;
            bottom: 0;
            left: 0;
            margin-left: 0;
            width: 100%;
            padding: 0 16px;
            height: 70px;
            z-index: 2;
          }
        }
        .sort_wrapper {
          // display: flex;
          align-items: center;
          width: 220px;
          height: 35px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          padding: 0 15px;
          margin: 0 10px;
          cursor: pointer;
          .txt {
            font-family: 'gtWalsheim';
            font-size: 14px;
            color: #707070;
          }
          @include media_query(XS) {
            display: none;
          }
          @include media_query(M) {
            display: flex;
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 170px;
          background: #38539a;
          border-radius: 24px;
          padding: 0 30px;
          cursor: pointer;
          white-space: nowrap;
          @include media_query(XS) {
            width: 100%;
            height: 35px;
          }
          @include media_query(M) {
            position: relative;
            width: auto;
            margin: 0;
          }
          &.disabled {
            background-color: #d9d9d9;
            cursor: default;
            .txt {
              color: #5e5e5e;
            }
          }
          .txt {
            font-family: 'gtWalsheim';
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            padding-left: 10px;
            text-transform: capitalize;
          }
          .add {
            width: 12px;
            height: 12px;
            background-image: url(../../styles/images/add-white.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
    @include media_query(XS) {
      margin-top: 25px;
    }
    @include media_query(M) {
      padding-left: 65px;
      padding-top: 150px;
      margin-top: 0;
      height: calc(100vh - 110px);
    }
    @include media_query(L) {
      height: 100%;
      // width: calc(100vw - 64px);
      padding-top: 150px;
      padding-left: 65px;
    }
    .inner_wrapper {
      display: flex;

      .table_main_wrapper {
        width: 100%;
        .tbl_header {
          display: flex;
          align-items: center;
          background-color: #2c2c2c;
          border-radius: 12px;
          height: 50px;
          padding: 0 24px;
          margin: 0 40px;
          margin-bottom: 16px;
          @include media_query(S) {
            display: none;
          }
          .tbl_head_wrapper {
            display: flex;
            align-items: center;
            width: 85%;
            @include media_query(M) {
              width: 85%;
            }
            .tbl_head {
              &:nth-child(1) {
                font-family: 'gtWalsheim';
                width: 35%;
              }
              &:nth-child(2) {
                font-family: 'gtWalsheim';
                width: 26%;
              }
              &:nth-child(3) {
                font-family: 'gtWalsheim';
                width: 27%;
              }
              &:nth-child(4) {
                font-family: 'gtWalsheim';
                width: 12%;
                text-align: center;
              }
            }
          }
          .tbl_head {
            font-family: 'gtWalsheim';
            font-size: 16px;
            font-weight: 500;
            color: #fafafa;
            &:nth-child(1) {
              width: 15%;
            }
          }
        }
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        .tbl_body {
          overflow: auto;
          background-color: #fff;
          padding: 0;
          // height: calc(100vh - 245px);
          @include media_query(S) {
            height: calc(100vh - 200px);
          }
          @include media_query(M) {
            padding: 0 40px;
            height: calc(100vh - 275px);
          }
          @include media_query(L) {
            // height: calc(100vh - 295px);
            // height: auto;
            overflow-y: auto;
          }

          .tbl_row_wrapper {
            .tbl_row {
              display: flex;
              min-height: 102px;
              background-color: #fff;
              box-shadow: 0px 2px 8px rgba(44, 44, 44, 0.1);
              padding: 0 24px;
              object-fit: cover;
              margin-bottom: 16px;
              cursor: pointer;
              @include media_query(S) {
                display: flex;
                min-height: auto;
                padding: 0 0;
                margin: 0 15px;
                padding-bottom: 15px;
                margin-bottom: 16px;
                box-shadow: none;
                border-bottom: 1px solid #e4e4e4;
                &:last-child {
                  border-bottom: none;
                }
              }
              @include media_query(M) {
                align-items: center;
                border-radius: 12px;
              }
              &:hover {
                @include media_query(M) {
                  background-color: #f4f4f4;
                }
              }
              &.selected {
                background-color: #f1f2ff;
              }
              .tbl_data_wrapper {
                position: relative;
                width: 85%;
                display: flex;
                align-items: center;
                object-fit: cover;
                @include media_query(M) {
                  width: 85%;
                }
                @include media_query(S) {
                  flex-direction: column;
                  align-items: flex-start;
                  padding-left: 10px;
                }
                .tbl_data {
                  &:nth-child(1) {
                    width: 35%;
                    @include media_query(S) {
                      width: 100%;
                      margin-bottom: 7px;
                    }
                    .inner_data {
                      object-fit: cover;
                      padding-right: 64px;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      @include media_query(L) {
                        padding-right: 24px;
                      }
                      @include media_query(S) {
                        padding-right: 0px;
                      }
                    }
                    .txt {
                      font-family: 'gtWalsheim';
                      font-size: 14px;
                      font-weight: 500;
                      color: #2c2c2c;
                      line-height: 18px;
                      min-height: 35px;
                      word-break: break-all;
                      @include media_query(M) {
                        font-size: 16px;
                      }
                    }
                  }
                  &:nth-child(2) {
                    width: 26%;
                    @include media_query(S) {
                      width: 100%;
                    }
                  }
                  &:nth-child(3) {
                    width: 27%;
                    @include media_query(S) {
                      width: 100%;
                      order: -1;
                      margin-bottom: 7px;
                    }
                  }
                  &:nth-child(4) {
                    width: 12%;
                    @include media_query(S) {
                      position: absolute;
                      bottom: 5px;
                      right: 0;
                      width: 50px;
                    }
                    .inner_data {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                    .txt {
                      font-family: 'gtWalsheim';
                      font-size: 14px;
                      color: #707070;
                    }
                    .courses_icon {
                      // opacity: 0.5;
                    }
                  }
                }
              }
              .tbl_data {
                &:nth-child(1) {
                  width: 15%;
                  @include media_query(S) {
                    width: 96px;
                  }
                  .inner_data {
                    padding-right: 16px;
                    @include media_query(XS) {
                      // padding-right: 0;
                    }
                    img {
                      width: 95px;
                      max-height: 62px;
                      height: 62px;
                      object-fit: cover;
                      border-radius: 10px;
                      @include media_query(S) {
                        height: 102px;
                        max-height: 102px;
                        max-width: 96px;
                      }
                    }
                  }
                }
              }
              .courses_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../styles/images/files_gray_icon.svg);
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 4px;
                @include media_query(M) {
                  margin-right: 12px;
                }
              }
              .txt {
                font-family: 'gtWalsheim';
                font-size: 16px;
                color: #2c2c2c;
                &.mob_font {
                  color: #707070;
                  @include media_query(M) {
                    color: #2c2c2c;
                  }
                }
                @include media_query(M) {
                  font-size: 14px;
                }
              }
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
      }
      // jjsbfjsb
      .content_wrapper {
        width: 100%;
        display: none;
        .image_container {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -12px;

          @include media_query(XS) {
            // flex-direction: column;
            flex-wrap: wrap;
          }
          @include media_query(M) {
            flex-direction: row;
          }
          @include media_query(L) {
            flex-direction: row;
          }

          .flex_item {
            flex: 12.5%;
            max-width: 12.5%;
            padding: 0 12px;
            @include media_query(M) {
              flex: calc(100% / 4);
              max-width: calc(100% / 4);
            }
            @include media_query(L) {
              flex: 12.5%;
              max-width: 12.5%;
            }
            &.selected {
              .image_wrapper {
                // border: 5px solid #38539a;
                box-sizing: border-box;
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                border-radius: 15px;
                position: relative;
                .pointer_icon_wrapper {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  background: #ffffff;
                  box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                  border-radius: 8px 0px 12px 0px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .pointer_img {
                    position: absolute;
                    width: 24px;
                    height: 20px;
                    background-image: url(../../styles/images/selected_img.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                }
              }
            }
          }
          .image_wrapper {
            width: 100%;
            height: 140px;
            margin-bottom: 24px;
            object-fit: cover;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }
        @include media_query(M) {
          padding: 0 40px;
          display: block;
          height: calc(100vh - 220px);
          overflow-y: auto;
        }

        // Table container
        // Table container end
      }
      .img_drawer_container {
        @include media_query(M) {
          background: none;
          padding-left: 8px;
        }
        .custom_overlay {
          width: 100%;
          height: 175px;
          position: fixed;
          top: 0px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          // position: ;
          background-color: rgba(0, 0, 0, 0.47);
          @include media_query(M) {
            display: none;
          }
        }

        .img_drawer_wrapper {
          display: none;
          background-color: #fff;
          position: fixed;
          top: 140px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          padding: 35px 0px 0px;
          padding-right: 4px;
          flex-shrink: 0;
          border-left: 1px solid #e4e4e4;
          border-radius: 20px 20px 0px 0px;
          // overflow-y: auto;
          @include media_query(M) {
            width: 500px;
            padding: 45px 0px 0;
            padding-right: 8px;
            right: 0;
            left: auto;
            top: 200px;
            // z-index: initial;
            border-radius: 0;
          }
          @include media_query(L) {
            top: 10px;
            padding-top: 0;
            height: calc(100vh - 110px);
            position: relative;
            top: 0;
          }

          .mob_bar {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 35px;
            background-color: #fafafa;
            border-radius: 20px 20px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .line_bar {
              width: 50px;
              height: 3px;
              background-color: #2c2c2c;
            }
            @include media_query(M) {
              display: none;
            }
          }
          .header {
            display: flex;
            position: relative;
            margin-bottom: 16px;
            .title {
              font-family: 'gtWalsheim';
              color: #2c2c2c;
              font-size: 24px;
              line-height: 28px;
              font-weight: 700;
            }
            .close_wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 10px;
              width: 32px;
              height: 32px;
              cursor: pointer;
              @include media_query(XS) {
                // top: 0px;
                display: none;
              }
              @include media_query(M) {
                display: flex;
                top: 0;
              }
              .close_icon {
                width: 12px;
                height: 12px;
                background-image: url(../../styles/images/close.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
          .img_wrapper {
            width: 100%;
            height: 235px;
            position: relative;
            margin-bottom: 25px;

            .crop_btn_wrapper {
              position: absolute;
              top: 10px;
              right: 10px;
              width: 35px;
              height: 35px;
              background-color: #f1f2ff;
              border-radius: 50%;
              display: none;
              cursor: pointer;
              .crop_btn {
                width: 15px;
                height: 15px;
                background-image: url(../../styles/images/cropIcon2.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
              @include media_query(M) {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .selected_img {
              width: 100%;
              height: 100%;
              background-image: url(../../styles/images/sample_image.svg);
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 10px;
              object-fit: cover;
            }
          }
          .middle_container {
            overflow-y: auto;
            height: 100%;
            padding: 24px 15px 0;
            @include media_query(M) {
              padding: 0px 32px 40px 32px;
            }
            .info_container {
              width: 100%;
              margin-bottom: 25px;
              color: #2c2c2c;
              &.mb_45 {
                @include media_query(XS) {
                  margin-bottom: 20px;
                }
                @include media_query(M) {
                  margin-bottom: 45px;
                }
              }
              .dropdown_container {
                position: absolute;
                width: 100%;
                top: 45px;
                background-color: #fff;
                border-radius: 10px;
                z-index: 1;
                border: 2px solid #707070;
                padding: 10px 0;
                .list_wrapper {
                  max-height: 185px;
                  overflow: auto;
                  .list {
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    font-family: 'gtWalsheim';
                    font-size: 14px;
                    font-weight: 500;
                    color: #2c2c2c;
                    cursor: pointer;
                    @include media_query(XS) {
                      height: auto;
                      margin-bottom: 20px;
                    }
                    @include media_query(M) {
                      min-height: 35px;
                      margin-bottom: 0;
                    }
                    &:hover {
                      font-family: 'gtWalsheim';
                      font-weight: 700;
                      color: #38539a;
                      background-color: #f4f4f4;
                    }
                  }
                }
              }
              &:last-child {
                margin: 0;
              }
              .label_wrapper {
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;
                .tag_text {
                  font-size: 16px;
                  font-weight: 400;
                  color: #707070;
                }
              }
              .form_field {
                position: relative;
                margin-bottom: 10px;
                .input_wrapper {
                  display: flex;
                  align-items: center;
                  position: relative;
                  .input_area {
                    position: relative;
                    width: calc(100% - 120px);
                  }
                  .input {
                    font-family: 'gtWalsheim';
                    width: 100%;
                    height: 35px;
                    border: 2px solid #2c2c2c;
                    border-radius: 10px;
                    font-size: 16px;
                    line-height: 35px;
                    font-weight: 500;
                    color: #2c2c2c;
                    padding: 0 12px;
                    &.active {
                      border: 2px solid #38539a;
                    }
                    &.ip2 {
                      // width: calc(100% - 120px);
                    }
                    &::placeholder {
                      font-weight: 200;
                    }
                  }

                  .textarea {
                    width: 100%;
                    height: 90px;
                    border: 2px solid #2c2c2c;
                    border-radius: 10px;
                    font-family: 'gtWalsheim';
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #2c2c2c;
                    padding: 10px 12px;
                    resize: vertical;
                    overflow: auto;
                    &::placeholder {
                      font-weight: 200;
                    }
                  }
                  .add_btn {
                    font-family: 'gtWalsheim';
                    width: 120px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 700;
                    color: #5e5e5e;
                    background: #d9d9d9;
                    border-radius: 24px;
                    margin-left: 10px;
                    cursor: pointer;
                    .plus_sign {
                      width: 12px;
                      height: 12px;
                      background-image: url(../../styles/images/plus_icon.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                      margin-right: 8px;
                    }
                    &.selected {
                      background-color: #2c2c2c;
                      color: #fff;
                      .plus_sign {
                        background-image: url(../../styles/images/add-white.svg);
                      }
                    }
                  }
                }
              }
              .pills_container {
                padding: 0;
              }
              &.error_wrapper {
                .input_wrapper {
                  .input {
                    border: 2px solid #d91734;
                  }
                  .error_icon_red {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 12px;
                    width: 20px;
                    height: 20px;
                    background-image: url('../../styles/images/error_icon_red.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                }

                .error_message {
                  color: #d91734;
                  font-family: 'gtWalsheim';
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                }
              }
            }
            .upload_details_wrapper {
              width: 100%;
              display: flex;
              margin-bottom: 30px;

              .upload_info {
                padding: 0 40px;
                border-left: 1px solid #e4e4e4;

                &:first-child {
                  padding-left: 0;
                  border: none;
                }
                .title {
                  font-family: 'gtWalsheim';
                  font-size: 16px;
                  font-weight: 500;
                  color: #2c2c2c;
                  margin-bottom: 10px;
                }
                .info_text {
                  font-family: 'gtWalsheim';
                  font-size: 14px;
                  font-weight: 400;
                  color: #949494;
                }
              }
            }
            .img_instances_container {
              .insturction_text_wrapper {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-bottom: 10px;
                .info_text {
                  font-family: 'gtWalsheim';
                  font-size: 14px;
                  font-weight: 600;
                  color: #2c2c2c;
                  padding-right: 10px;
                  cursor: pointer;
                }
                .replace_icon {
                  width: 15px;
                  height: 15px;
                  background-image: url(../../styles/images/replace_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: pointer;
                }
              }
              .dropdown_container {
                // padding: 20px 0px;
                margin-bottom: 50px;
                border: 1px solid #e4e4e4;
                border-radius: 10px;
                position: relative;
                .top_bar {
                  width: 100%;
                  height: 50px;
                  background-color: #f4f4f4;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  position: relative;
                  padding: 0 20px;
                  cursor: pointer;
                  .title {
                    font-family: 'gtWalsheim';
                    font-size: 14px;
                    font-weight: 500;
                    color: #2c2c2c;
                    padding-right: 20px;

                    @include media_query(M) {
                      font-size: 18px;
                    }
                  }
                  .toggle_arrow {
                    position: absolute;
                    right: 20px;
                    width: 15px;
                    height: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.up_arrow {
                      transform: rotate(180deg);
                      background-image: url(../../styles/images/down_arrow_black.svg);
                    }
                    &.down_arrow {
                      background-image: url(../../styles/images/down_arrow_black.svg);
                    }
                  }
                }
                .list_wrapper {
                  // position: absolute;
                  top: 100%;
                  width: 100%;
                  height: auto;
                  .list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    width: 100%;
                    height: 50px;
                    border-bottom: 1px solid #e4e4e4;
                    &:last-child {
                      border: none;
                    }
                    .item_wrapper {
                      display: flex;
                      align-items: center;
                      max-width: 70%;
                      max-height: 45px;
                      overflow: hidden;
                      .item_icon {
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: 15px;
                        &.item_art_icon {
                          width: 14px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../styles/images/book_icon.svg);
                        }
                        &.item_sci_icon {
                          width: 18px;
                          height: 16px;
                          background-image: url(../../styles/images/book_icon2.svg);
                        }
                      }
                      .item {
                        font-family: 'gtWalsheim';
                        font-size: 14px;
                        font-weight: 500;
                        color: #2c2c2c;
                      }
                    }
                    .instance_status {
                      width: 100px;
                      height: 25px;
                      padding: 0 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      font-weight: 500;
                      font-family: 'gtWalsheim';
                      &.live_sts {
                        color: #107f47;
                        background-color: #f0fae5;
                      }
                      &.draft_sts {
                        color: #a36a00;
                        background-color: #fff9f0;
                      }
                      &.offline_sts {
                        color: #62625a;
                        background-color: #f0f0f0;
                      }
                      &.go_live_sts {
                        color: #38539a;
                        background-color: #f1f2ff;
                      }
                      &.archive_sts {
                        color: #d91734;
                        background-color: #fdf4f4;
                      }
                    }
                  }
                }
              }
            }
            .empty_instances_wrapper {
              width: 100%;
              font-family: 'gtWalsheim';
              height: 48px;
              background: #f4f4f4;
              border-radius: 10px;
              margin-bottom: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              .txt {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #707070;
                @include media_query(M) {
                  font-size: 18px;
                  line-height: 21px;
                }
              }
            }
            .btn_wrapper {
              display: flex;
              font-family: 'gtWalsheim';
              flex-direction: column;
              justify-content: center;
              padding: 0;
              margin-bottom: 20px;
              &.disabled {
                pointer-events: none;
                .btn {
                  background-color: #d9d9d9;
                  color: #5e5e5e;
                  border: transparent;
                }
                .delete_icon {
                  background-image: url(../../styles/images/delete-black.svg);
                }
              }
              &.justify_left {
                justify-content: left;
              }
              .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 120px;
                height: 35px;
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 700;
                // background-color: #d9d9d9;
                border: 2px solid #2c2c2c;
                color: #2c2c2c;
                border-radius: 24px;
                margin: 0 6px 0;
                padding: 0 30px;
                white-space: nowrap;
                cursor: pointer;
              }
              .delete_icon {
                width: 16px;
                height: 18px;
                background-image: url(../../styles/images/delete-black-update.svg);
                background-repeat: no-repeat;
                background-size: cover;
                margin: 0 10px;
              }
              &:hover {
                .btn {
                  background-color: #d91734;
                  color: #fff;
                  border: 2px solid #d91734;
                }
                .delete_icon {
                  background-image: url(../../styles/images/delete-white.svg);
                }
              }
            }
            .suggestion_text {
              font-family: 'gtWalsheim';
              font-size: 14px;
              color: #a36a00;
              min-height: 50px;
              font-weight: 400;
              background: #fff9f0;
              border: 1px solid #d1b278;
              border-radius: 12px;
              padding: 10px;
              margin-bottom: 20px;
            }
          }
          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #e4e4e4;
            border-radius: 10px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #707070;
            border-radius: 10px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #707070;
          }
        }
      }

      @include media_query(XS) {
        padding: 0 0px;
      }
    }
  }

  .modal_wrapper {
    // position: fixed;
    // display: flex;
    // align-items: center;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background: rgba(112, 112, 112, 0.7);
    // z-index: 10;
    // .delete_modal {
    //   display: flex;
    //   flex-direction: column;
    //   position: relative;
    //   margin: auto;
    //   background-color: #fff;
    //   padding: 20px 30px 15px 30px;
    //   @include media_query(XS) {
    //     min-width: 315px;
    //     min-height: 230px;
    //     max-width: 100%;
    //     max-height: 400px;
    //   }
    //   @include media_query(M) {
    //     min-width: 390px;
    //     min-height: 230px;
    //     max-width: 400px;
    //     max-height: 400px;
    //   }
    .header_wrapper {
      padding: 0 10px 35px 0;

      .heading {
        font-family: 'gtWalsheim';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }

    .close_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: #2c2c2c;
      cursor: pointer;

      .close_icon {
        width: 10px;
        height: 10px;
        background-image: url(../../styles/images/close-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;

      .warning_icon {
        width: 18px;
        height: 18px;
        background-image: url(../../styles/images/warning-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 700;
          color: #a36a00;
        }

        .sub_heading {
          font-family: 'gtWalsheim';
          font-size: 14px;
          color: #a36a00;
        }
      }
    }

    .confirm_wrapper {
      padding-bottom: 45px;

      .txt {
        font-family: 'gtWalsheim';
        font-size: 14px;
        color: #2c2c2c;
        margin-bottom: 10px;
      }

      .input_wrapper {
        position: relative;
        .input {
          width: 100%;
          height: 35px;
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          padding: 0 10px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          &.error_input {
            border: 2px solid #d91734;
          }
          &::placeholder {
            font-family: 'gtWalsheim';
            font-weight: 200;
            color: #707070;
          }
        }
        .error_icon {
          display: flex;
          align-items: center;
          transform: translate(-50%, 25%);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 23px;
          height: 23px;
          background-image: url(../../styles/images/insufficient.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
        .error_msg {
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 500;
          color: #d91734;
          margin-top: 8px;
        }
      }
    }

    .confirm_msg {
      text-align: center;

      .txt {
        display: inline-block;
        vertical-align: middle;
        font-family: 'gtWalsheim';
        font-size: 16px;
        color: #2c2c2c;
      }

      .bold {
        font-family: 'gtWalsheim';
        font-weight: 700;
      }
    }

    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      .btn {
        min-width: 120px;
        height: 35px;
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px;
        padding: 0 30px;
        cursor: pointer;

        &.active {
          background-color: #d91734;
          border: 2px solid transparent;
          color: #fff;
        }

        &.save_changes {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }

        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
        }
      }
    }
    // }
  }

  // Notification bar code here
  .notification_wrapper {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 450px;
    height: auto;
    z-index: 100;
    @include media_query(XS) {
      width: fit-content;
    }
    @include media_query(M) {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .noti_bar {
      width: 100%;
      min-height: 60px;

      border-radius: 12px;
      padding: 12px 35px;
      position: relative;

      &.success_bar {
        background-color: #f0fae5;
        border: 1px solid #7fbc96;
      }
      &.failure_bar {
        background-color: #fff9f0;
        border: 1px solid #d1b278;
      }
      &.insufficient_bar {
        background: #fdf4f4;
      }
      .text_wrapper {
        &.success_txt {
          color: #107f47;
        }
        &.failure_txt {
          color: #a36a00;
        }
        &.insufficient_txt {
          color: #d91734;
        }
        .tagline {
          font-family: 'gtWalsheim';
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
        }
        .description {
          font-family: 'gtWalsheim';
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
        }
      }
      .left_icon {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        &.success_tick_icon {
          background-image: url(../../styles/images/success_icon.svg);
        }
        &.failure_icon {
          width: 18px;
          // background-image: url(../../styles/images/failure_icon.svg);
        }
        &.insufficient_icon {
          width: 18px;
          background-image: url(../../styles/images/insufficient.svg);
          background-size: contain;
        }
      }

      .right_icon {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &.green_close_icon {
          background-image: url(../../styles/images/close_green_icon.svg);
        }
        &.red_close_icon {
          background-image: url(../../styles/images/close_red_icon.svg);
          // background-image: url(../../../styles/images/close_red_icon.svg);
        }
      }
    }
  }
  // Notification bar End
  &.content_mgt_wrapper_disable_side_nav {
    width: calc(100%) !important;
    z-index: 6 !important;
    position: relative !important;
    @include media_query(XS) {
      left: auto !important;
    }
    @include media_query(M) {
      left: -65px !important;
    }
    .header_main_wrapper {
      @include media_query(M) {
        padding-left: 65px;
      }
      @include media_query(L) {
        position: fixed;
        width: calc(100%);
        top: 0;
        z-index: 3;
        margin-bottom: 40px;
        padding-left: 0;
      }
      .header_wrapper {
        display: flex;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        // height: 110px;
        padding: 0 40px;
        @include media_query(XS) {
          min-height: 35px;
          padding-bottom: 15px;
          justify-content: center;
        }
        @include media_query(M) {
          min-height: auto;
          height: 110px;
          padding-bottom: 0;
          justify-content: left;
        }
        .heading {
          font-family: 'gtWalsheim';
          font-weight: 700;
          color: #2c2c2c;
          @include media_query(XS) {
            font-family: 'gtWalsheim';
            font-size: 24px;
          }
          @include media_query(M) {
            font-family: 'gtWalsheim';
            font-size: 32px;
          }
        }
      }
    }
    .audience_wrapper {
      height: 100%;
      position: relative;
      // z-index: 1;
      z-index: 2;
      background: white;
      @include media_query(XS) {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
      }
      @include media_query(M) {
        padding-top: 150px;
        padding-left: 65px;
        width: calc(100% + 65px);
      }
    }
  }
}
.toggle_section_wapper {
  position: relative;
  display: none;
  width: 120px;
  height: 32px;
  margin-left: auto;
  @include media_query(M) {
    display: flex;
    align-items: center;
  }
}
.image_lib_shimmer {
  @include media_query(XS) {
    height: 110px;
    margin: 0px 16px 16px 16px;
  }
  @include media_query(M) {
    margin: 0px 0px 16px 0px;
  }
}
