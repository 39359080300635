@import '../../../../styles/common/media_queries';

.modal_wrapper_main {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  @include media_query(XS) {
    display: flex;
  }
  @include media_query(M) {
    display: none;
  }
  .modal_wrapper {
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: rgba(112, 112, 112, 0.7);
    z-index: 0;
    @include media_query(XS) {
      display: flex;
    }
    @include media_query(M) {
      display: none;
    }
  }
  .delete_modal {
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: #fff;
    // padding: 20px 30px 15px 30px;
    @include media_query(XS) {
      display: flex;
      min-width: 100%;
      min-height: 40%;
      max-width: 100%;
      max-height: 83%;
      margin-bottom: 0;
      padding-top: 35px + 17px;
      border-radius: 20px 20px 0px 0px;
    }
    @include media_query(M) {
      display: none;
    }
    .mob_bar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      background-color: #fafafa;
      border-radius: 20px 20px 0px 0px;
      align-items: center;
      justify-content: center;

      .line_bar {
        width: 50px;
        height: 3px;
        background-color: #2c2c2c;
      }
      @include media_query(XS) {
        display: flex;
      }
      @include media_query(M) {
        display: none;
      }
    }

    .status_main_wrapper {
      padding: 0 32px;
    }

    .header_wrapper {
      align-items: center;
      height: 72px;
      padding: 20px 40px 20px 32px;
      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
      margin-bottom: 24px;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
      }
      .heading {
        font-family: 'gtWalsheim';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }

    .header_wrapper_xs {
      @include media_query(XS) {
        display: flex;
        padding-bottom: 12px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e4e4e4;
      }
      @include media_query(M) {
        display: none;
        margin-bottom: 0;
      }
      .heading {
        font-family: 'gtWalsheim';
        font-size: 16px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }

    .status_wrapper {
      padding: 0 8px;
      .status_list {
        display: flex;
        align-items: center;
        padding-bottom: 28px;
        .status_icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          &.live {
            background: #107f47;
          }
          &.draft {
            background: #a65523;
          }
          &.offline {
            background: #707070;
          }
        }
        .txt {
          font-family: 'gtWalsheim';
          font-size: 16px;
          color: #2c2c2c;
          padding-left: 8px;
          line-height: initial;
        }
      }
    }
  }
}
