@import '../../../styles/common/media_queries';

// EMpty screen added
.empty_container {
  width: 100%;
  //  height: calc(100% - 70px);
  @include media_query(M) {
    height: 100%;
  }
}
.empty_screen_wrapper {
  width: 100%;
  height: 100%;

  &.page_height {
    height: calc(100% - 95px);
  }
  &.block_wise_height {
    height: 100%;
  }

  .inner_wrapper_empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media_query(M) {
      // background-color: #FAFAFA;
    }
  }
  .empty_screen {
    min-width: 160px;
    max-width: 250px;
    @include media_query(M) {
      min-width: 355px;
    }
    .image_wrapper {
      background-repeat: no-repeat;
      width: 140px;
      height: 270px;
      margin: 0 auto;
      margin-bottom: 20px;
      background-size: cover;
      @include media_query(M) {
        min-width: 300px;
        height: 260px;
      }

      &.empty_img {
        width: 150px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/Illustration_emptyScreen.svg);
        @include media_query(M) {
          width: 355px;
          height: 325px;
        }
      }
    }
  }
}
// common class
.content_wrapper {
  text-align: center;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .label {
    font-family: 'gtWalsheim';
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .content {
    font-family: 'gtWalsheim';
    font-size: 16px;
    line-height: 24px;
  }
}
.empty_screen_wrapper_small {
  width: 100%;
  height: 100%;

  &.page_height {
    height: calc(100% - 95px);
  }
  &.block_wise_height {
    height: 100%;
  }

  .inner_wrapper_empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media_query(M) {
      // background-color: #FAFAFA;
    }
  }
  .empty_screen {
    min-width: 160px;
    max-width: 250px;
    @include media_query(M) {
      min-width: 355px;
    }
    .image_wrapper {
      background-repeat: no-repeat;
      width: 140px;
      height: 270px;
      margin: 0 auto;
      margin-bottom: 20px;
      background-size: cover;
      @include media_query(M) {
        min-width: 300px;
        height: 260px;
      }

      &.empty_img {
        width: 150px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/Illustration_emptyScreen.svg);
        @include media_query(M) {
          width: 355px;
          height: 150px;
        }
      }
    }
  }
}
.content_wrapper_small {
  text-align: center;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .label {
    font-family: 'gtWalsheim';
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .content {
    font-family: 'gtWalsheim';
    font-size: 12px;
    line-height: 18px;
  }
}
